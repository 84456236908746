import { createRef, FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { StickyView } from 'components/sticky-view';
import { CorButton } from 'components/cor-button';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { ContentstackText, ContentstackMessage, ContentstackImage } from 'components/contentstack';
import { StickySectionsKeys } from 'context/sticky-sections-heights-context';
import { useStickyOffsets } from 'hooks/use-sticky-offsets';

import './account-warning.scss';

interface IAccountNotificationProps {
  contentstackPath: string;
  className?: string;
  messageType: 'notifications' | 'success' | 'error' | 'tooltips';
  messageId: string;
}

export const AccountWarning: FC<IAccountNotificationProps> = ({
  contentstackPath,
  className,
  messageType,
  messageId,
}) => {
  const { isMobile } = useBreakpoint();
  const ref = createRef<HTMLDivElement>();
  const [isBannerExpanded, setIsBannerExpanded] = useState(false);
  const [hasHiddenContent, setHasHiddenContent] = useState(false);

  const accountWarningAdditionalContainerSize = 26;

  const { offset } = useStickyOffsets({
    ref,
    sectionKey: StickySectionsKeys.accountWarning,
    isBannerExpanded,
    additionalSpacing: accountWarningAdditionalContainerSize,
  });

  useEffect(() => {
    if (!ref?.current) return;
    setHasHiddenContent(ref.current.scrollHeight > ref.current.offsetHeight);

    const button = ref.current.parentElement?.querySelector('.account-warning__content-toggle');

    if (hasHiddenContent || isBannerExpanded) {
      button?.classList.remove('hidden');
    } else {
      button?.classList.add('hidden');
    }
  }, [ref, hasHiddenContent, isBannerExpanded]);

  const bannerContentClasses = classNames('account-warning__inner', {
    'account-warning__inner--collapsed': isMobile && !isBannerExpanded,
    'account-warning__inner--expanded': isBannerExpanded,
  });

  return (
    <StickyView offsetTop={offset} className={classNames('account-warning', className)}>
      <div className="account-warning__inner-wrapper">
        <div className="grid-x align-center account-warning__content">
          <div className="account-warning__container">
            {isMobile && (
              <ContentstackImage
                className="account-warning__warning-icon"
                contentKey={`${contentstackPath}.warning_icon`}
              />
            )}
            <div className={bannerContentClasses} ref={ref}>
              {!isMobile && (
                <ContentstackImage
                  className="account-warning__warning-icon"
                  contentKey={`${contentstackPath}.warning_icon`}
                />
              )}
              <ContentstackMessage type={messageType} messageId={messageId} />
            </div>
            {isMobile && (hasHiddenContent || (!hasHiddenContent && isBannerExpanded)) && (
              <CorButton
                color="link-2"
                className="account-warning__content-toggle"
                onClick={() => {
                  setIsBannerExpanded(!isBannerExpanded);
                }}
              >
                <ContentstackText
                  contentKey={`common[0].${isBannerExpanded ? 'hide_button_label' : 'more_button_label'}`}
                />
              </CorButton>
            )}
          </div>
        </div>
      </div>
    </StickyView>
  );
};
