import { FC, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { selectAddCreditCardFormServerError } from 'store/payment-methods/selectors';
import {
  createValidationSchema,
  filterFormikErrors,
  nameValidator,
  validateFormik,
  zipCodeValidator,
} from 'utils/validation';
import { ErrorNotification } from 'components/forms/error-notification';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { useContent } from 'hooks/use-content';
import { hostedPaymentFieldsStyleObj } from 'constants/hosted-payment-fields-style-object';
import { useAddOrRemovePaymentMethodSuccesOrErrorModal } from 'hooks/use-add-or-remove-payment-method-success-or-error-modal';
import { addCreditCard, clearAddCreditCardFormErrors, getPaymentMethods } from 'store/payment-methods/actions';
import { IAddCreditCardPayload } from 'store/payment-methods/saga/add-credit-card';
import { CreditCardImageUrl } from 'constants/credit-card-type-imge-url';
import { selectPaymentFieldTokenData } from 'store/payment-field-token/selectors';
import {
  HostedFieldsPaymentErrorCodes,
  HostedPaymentFieldInvalidMsg,
} from 'constants/hosted-payment-fields-error-codes';
import { TextField } from 'components/forms/text-field';
import { PaymentFieldsTagIds } from 'constants/hosted-payment-fields-tag-ids';
import iconCreditCardLock from 'assets/images/icons/icon-credit-card-loc.svg';
import iconCreditCardType from 'assets/images/icons/icon-credit-card-type.svg';
import iconCreditCardHelp from 'assets/images/icons/icon-credit-card-help.svg';
import { CustomTooltip } from 'components/custom-tooltip';
import { ContentstackMessage, ContentstackText } from 'components/contentstack';
import { CommonBluesnapErrorIds } from 'constants/common-bluesnap-error-ids.enum';
import { CollapsibleConsentSection } from 'components/collapsible-consent-section';
import { CorButton } from 'components/cor-button';
import { useScrollToRef } from 'hooks/use-scroll-to-ref';
import { useGetBlueSnapToken } from 'hooks/use-get-blue-snap-token';
import { useBlueSnapHostedPaymentFields } from 'hooks/use-blue-snap-hosted-payment-fields';

import './add-credit-card-form.scss';

export interface IAddCreditCardValues {
  firstName: string;
  lastName: string;
  zipCode: string;
  pfToken: string;
}

const initialValues: IAddCreditCardValues = {
  firstName: '',
  lastName: '',
  zipCode: '',
  pfToken: '',
};

export interface IAddCreditCardFormProps {
  contentStackPath: string;
  onClose: () => void;
}

export const AddCreditCardForm: FC<IAddCreditCardFormProps> = ({ contentStackPath, onClose }) => {
  const pfToken = useSelector(selectPaymentFieldTokenData);
  const { getContentByKey, getMessageText } = useContent();
  const { isMobile } = useBreakpoint();
  const dispatch = useDispatch();
  const imageRef = useRef<HTMLImageElement>(null);
  const formClassName = 'add-credit-card-form';
  const firstLastNameInputMaxLength = 70;
  const zipCodeMaxLength = 20;
  const ccnPlaceHolderPath = `${contentStackPath}.payment_card_form[0].account_number_place_holder`;
  const cvvPlaceHolderPath = `${contentStackPath}.payment_card_form[0].security_code_place_holder`;
  const expPlaceHolder = `${contentStackPath}.payment_card_form[0].expiration_date_place_holder`;
  const consentTextPath = `${contentStackPath}.payment_card_form[0].consent_section[0].consent_text`;
  const consentCheckboxLabelPath = `${contentStackPath}.payment_card_form[0].consent_section[0].checkbox_label`;
  const { ccn, exp, cvv } = PaymentFieldsTagIds;

  const serverError = useSelector(selectAddCreditCardFormServerError);
  const hasServerError = serverError !== '';
  const clientErrorMessage = getMessageText('error', 'MSG190');
  const [BSErrorMessage, setBSErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [hasBSError, setHasBSError] = useState(true);
  const [hasBSServerError, setHasBSServerError] = useState(false);
  const [isConsentChecked, setConsentStatus] = useState(false);
  const [validTags, setValidTags] = useState<string[]>([]);
  const { EMPTY, INVALID, CC_TYPE_NOT_SUPPORTED, INVALID_CC_NUMBER } = HostedFieldsPaymentErrorCodes;

  const isSubmittedRef = useRef(submitted);
  const { addPaymentMethodSuccessModal, addPaymentMethodFailModal } = useAddOrRemovePaymentMethodSuccesOrErrorModal();

  const getImpactedElement = (tagId: string) => document.querySelector(`[tag-id=${tagId}]`);
  const getElementLabel = (tagId: string) => document.getElementById(`${tagId}__label`);
  const getElementWithErrorMessage = (tagId: string) => document.querySelector(`.error-msg__${tagId}`);
  const errorNotificationRef = useRef<HTMLDivElement | null>(null);

  const onBlueSnapErrorCallBack = (errorCode?: number, errorMsgId?: string) => {
    setHasBSError(true);
    errorCode === 500 && setHasBSServerError(true);
    setBSErrorMessage(getMessageText('error', errorMsgId ? errorMsgId : 'MSG189'));
  };

  const isError = (submitted && hasErrors) || hasServerError || hasBSServerError;
  const { getPaymentToken } = useGetBlueSnapToken(onBlueSnapErrorCallBack);

  useEffect(() => {
    getPaymentToken();

    return () => {
      dispatch(clearAddCreditCardFormErrors());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPaymentToken]);

  const addElementLabelClassName = (tagId: string, addClass: string) => {
    const label = getElementLabel(tagId);
    if (label) {
      label.classList.add(addClass);
    }
  };

  const removeElementLabelClass = (tagId: string, removeClass: string) => {
    const label = getElementLabel(tagId);
    if (label) {
      label.classList.remove(removeClass);
    }
  };

  const addClassNameForBsElements = (tagId: string, addClass: string) => {
    const element = getImpactedElement(tagId);
    if (element) {
      element.classList.add(addClass);
    }
  };
  const changeClassName = (tagId: string, removeClass: string, addClass: string) => {
    const element = getImpactedElement(tagId);
    if (element) {
      element.classList.remove(removeClass);
      element.classList.add(addClass);
    }
  };

  const onFocus = (tagId: string) => {
    const label = getElementLabel(tagId);
    const elementWithErrorMessage = getElementWithErrorMessage(tagId);

    if (label && elementWithErrorMessage) {
      removeElementLabelClass(tagId, 'error-label');
      elementWithErrorMessage.textContent = '';
    }
    addClassNameForBsElements(tagId, 'on-focus');
  };

  const onError = (tagId: string, errorCode: string, errorDescription: string) => {
    const elementWithErrorMessage = getElementWithErrorMessage(tagId);
    const label = getElementLabel(tagId);

    if (elementWithErrorMessage && label) {
      if (errorCode) {
        if (validTags.includes(tagId)) {
          setValidTags(validTags.splice(validTags.indexOf(tagId), 1));
        }

        setHasBSError(true);
        addElementLabelClassName(tagId, 'error-label');
        changeClassName(tagId, 'on-focus', 'invalid-input');

        if (errorCode === (INVALID.code || EMPTY.code)) {
          if (errorDescription === EMPTY.description) {
            elementWithErrorMessage.textContent = getMessageText('error', 'MSG184');
          }
          if (errorDescription === INVALID.description) {
            elementWithErrorMessage.textContent = getMessageText('error', `${HostedPaymentFieldInvalidMsg[tagId]}`);
          }
        } else if (errorCode === CC_TYPE_NOT_SUPPORTED.code) {
          elementWithErrorMessage.textContent = getMessageText('error', 'MSG200');
        } else {
          setBSErrorMessage(getMessageText('error_two', 'MSG201'));
          setHasBSServerError(true);
        }
      } else {
        setHasBSError(false);
      }
    }
  };

  const onType = (tagId: string, cardType: string, cardData: string) => {
    setHasBSServerError(false);
    if (imageRef.current) {
      imageRef.current.src =
        null != cardData || CreditCardImageUrl[cardType] ? CreditCardImageUrl[cardType] : iconCreditCardType;
    }
  };

  const onValid = (tagId: string) => {
    if (!validTags.includes(tagId)) {
      validTags.push(tagId);
    }
    if (validTags.length === 3) {
      setHasBSError(false);
    }
  };

  const onBlur = () => {
    if (isSubmittedRef.current) {
      isSubmittedRef.current = false;

      dispatch(clearAddCreditCardFormErrors());
      setHasBSServerError(false);
      setSubmitted(false);
      setHasErrors(false);
    }
  };

  const getPlaceHolder = (path: string) => getContentByKey(path, '');

  const bsObj = {
    onFieldEventHandler: {
      onType: onType,
      onFocus: onFocus,
      onBlur: onBlur,
      onError: onError,
      onValid: onValid,
    },
    style: hostedPaymentFieldsStyleObj,
    ccnPlaceHolder: getPlaceHolder(ccnPlaceHolderPath),
    cvvPlaceHolder: getPlaceHolder(cvvPlaceHolderPath),
    expPlaceHolder: getPlaceHolder(expPlaceHolder),
  };
  const { createHostedPaymentFields, submitHostedPaymentFieldsData } = useBlueSnapHostedPaymentFields(bsObj, pfToken);

  useEffect(() => {
    createHostedPaymentFields();
  }, [createHostedPaymentFields]);

  const validateForm = (values: IAddCreditCardValues) => {
    validateFormik(values, validationSchema, setHasErrors);
  };

  const clearErrors = useCallback(() => {
    if (hasServerError || hasBSServerError) {
      dispatch(clearAddCreditCardFormErrors());
      setHasBSServerError(false);
    }
  }, [dispatch, hasServerError, hasBSServerError]);

  const validationSchema = createValidationSchema({
    firstName: nameValidator({
      wrongFormat: getMessageText('error', 'MSG005'),
      required: getMessageText('error', 'MSG004'),
    }),
    lastName: nameValidator({
      wrongFormat: getMessageText('error', 'MSG005'),
      required: getMessageText('error', 'MSG004'),
    }),
    zipCode: zipCodeValidator({
      required: getMessageText('error', 'MSG101'),
      wrongFormat: getMessageText('error', 'MSG183'),
    }),
  });

  const onAddPaymentCardFail = useCallback(
    (status: number, errorId: string) => {
      getPaymentToken();

      if (errorId === CommonBluesnapErrorIds.CONTACT_BANK) {
        return;
      }

      if (status === 400) {
        setHasErrors(true);
        return;
      }

      addPaymentMethodFailModal({ status });
    },
    [getPaymentToken, addPaymentMethodFailModal]
  );

  const bsFailCallBack = useCallback(
    (callback) => {
      setHasBSServerError(true);
      const errorArray = new Array(callback.error);

      errorArray.forEach((error) => {
        error.errorCode === INVALID_CC_NUMBER.code
          ? setBSErrorMessage(getMessageText('error', 'MSG200'))
          : setBSErrorMessage(getMessageText('error_two', 'MSG201'));
      });
    },
    [getMessageText, INVALID_CC_NUMBER]
  );

  const bsSuccessCallBack = useCallback(
    (values: IAddCreditCardValues) => {
      dispatch(
        addCreditCard.request<IAddCreditCardPayload>({
          ...values,
          pfToken,
          onSuccessCallBack: addPaymentMethodSuccessModal,
          onFailCallBack: onAddPaymentCardFail,
        })
      );
      dispatch(getPaymentMethods.request());
    },
    [dispatch, pfToken, onAddPaymentCardFail, addPaymentMethodSuccessModal]
  );

  const submitAddCreditCardForm = (values: IAddCreditCardValues) => {
    if (submitted) return;

    isSubmittedRef.current = true;
    setSubmitted(true);
    validateForm(values);
    submitHostedPaymentFieldsData(bsFailCallBack, bsSuccessCallBack, values);
  };

  const handleConsentStatusChange = () => {
    setConsentStatus((status) => !status);
  };

  const isSubmitButtonDisabled = (values: IAddCreditCardValues) =>
    Object.values(values).some((value) => value === '') ||
    hasErrors ||
    (submitted && hasServerError) ||
    hasBSError ||
    hasBSServerError ||
    !isConsentChecked;

  useScrollToRef({
    ref: errorNotificationRef,
    isError,
  });

  return (
    <>
      <div className={formClassName}>
        {isError && (
          <div className="grid-x" ref={errorNotificationRef}>
            <div className="cell">
              <ErrorNotification
                className="error-notification"
                messageId={serverError}
                message={hasBSServerError ? BSErrorMessage : serverError || clientErrorMessage}
              />
            </div>
          </div>
        )}
        <Formik
          validateOnChange={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitAddCreditCardForm}
          validate={validateForm}
        >
          {({ values, errors, setErrors }) => (
            <Form
              onChange={(e: SyntheticEvent) => {
                clearErrors();
                isSubmittedRef.current = false;
                setErrors(filterFormikErrors(errors, (e.target as HTMLInputElement).name));
              }}
              onBlur={(e: SyntheticEvent) => {
                validateForm(values);
                setErrors(filterFormikErrors(errors, (e.target as HTMLInputElement).name));
              }}
            >
              <div className={`${formClassName}__field-contact-name`}>
                <TextField
                  id="firstName"
                  name="firstName"
                  maxLength={firstLastNameInputMaxLength}
                  label={getContentByKey<string>(`${contentStackPath}.payment_card_form[0].first_name_label`, '')}
                  placeholder={getContentByKey<string>(
                    `${contentStackPath}.payment_card_form[0].first_name_place_holder`,
                    ''
                  )}
                />
                <TextField
                  id="lastName"
                  name="lastName"
                  maxLength={firstLastNameInputMaxLength}
                  label={getContentByKey<string>(`${contentStackPath}.payment_card_form[0].last_name_label`, '')}
                  placeholder={getContentByKey<string>(
                    `${contentStackPath}.payment_card_form[0].last_name_place_holder`,
                    ''
                  )}
                />
              </div>
              <div className={`${formClassName}__field`}>
                <label className="text-field__label" id={`${ccn}__label`}>
                  {getContentByKey<string>(`${contentStackPath}.payment_card_form[0].account_number_label`, '')}
                </label>
                <div className={`${formClassName}__card-type`} tag-id={ccn}>
                  <div className={` ${formClassName}__input-ccn`} id="card-number" data-bluesnap={ccn} />
                  <div className={`${formClassName}__card-type-image-block`}>
                    <img
                      ref={imageRef}
                      alt="card-type"
                      className={`${formClassName}__card-type-image`}
                      src={iconCreditCardType}
                      height="20px"
                    />
                    <img
                      alt="card-lock"
                      className={`${formClassName}__card-lock-image`}
                      src={iconCreditCardLock}
                      height="20px"
                    />
                  </div>
                </div>
              </div>
              <div className={`error-msg__${ccn}`} />
              <div className={`${formClassName}__field ${formClassName}__field-expiration`}>
                <div>
                  <label className="text-field__label" id={`${exp}__label`}>
                    {getContentByKey<string>(`${contentStackPath}.payment_card_form[0].expiration_date_label`, '')}
                  </label>
                  <div
                    className={`${formClassName}__input ${formClassName}__input-exp`}
                    data-bluesnap="exp"
                    id="exp-date"
                    tag-id={exp}
                  />
                  <div className={`error-msg__${exp}`} />
                </div>
                <TextField
                  id="zipCode"
                  name="zipCode"
                  maxLength={zipCodeMaxLength}
                  label={getContentByKey<string>(`${contentStackPath}.payment_card_form[0].postal_code_label`, '')}
                  placeholder={getContentByKey<string>(
                    `${contentStackPath}.payment_card_form[0].postal_code_place_holder`,
                    ''
                  )}
                />
                <div className={`${formClassName}____field-expiration-cvv`}>
                  <label className="text-field__label" id={`${cvv}__label`}>
                    {getContentByKey<string>(`${contentStackPath}.payment_card_form[0].security_code_label`, '')}
                  </label>
                  <div className={`${formClassName}__input-cvv`} tag-id={cvv}>
                    <div id="cvv" data-bluesnap={cvv} />
                    <CustomTooltip tooltipId="cvv-tooltip" customIcon={iconCreditCardHelp}>
                      <ContentstackMessage type="tooltips" messageId="MSG195" />
                    </CustomTooltip>
                  </div>
                  <div className={`error-msg__${cvv}`} />
                </div>
              </div>
              <div className={`${formClassName}__field`}>
                <CollapsibleConsentSection
                  isChecked={isConsentChecked}
                  onCheckedStatusChange={handleConsentStatusChange}
                  consentTextPath={consentTextPath}
                  checkboxLabelPath={consentCheckboxLabelPath}
                />
              </div>
              <div className={`${formClassName}__buttons`}>
                <div className={`${formClassName}__buttons-cancel`} onClick={onClose}>
                  <ContentstackText contentKey={`${contentStackPath}.payment_card_form[0].cancel_button`} />
                </div>
                <CorButton
                  className={`${formClassName}__buttons-submit`}
                  type="submit"
                  disabled={isSubmitButtonDisabled({ ...values, pfToken })}
                >
                  <ContentstackText
                    contentKey={`${contentStackPath}.payment_card_form[0].${
                      isMobile ? 'submit_button' : 'submit_button_desktop'
                    }`}
                  />
                </CorButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
