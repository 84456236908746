import { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import { ContentstackText } from 'components/contentstack';
import { IGuideItem } from 'types/guide-tile';
import { CorButton } from 'components/cor-button';

import './empty-shopping-list-slider.scss';

interface IEmptyShoppingListSliderProps {
  contentPath?: string;
  guideTabs?: IGuideItem[];
  deletedLocation?: boolean;
  openCreateListModal?(): void;
}

export const EmptyShoppingListSlider: FC<IEmptyShoppingListSliderProps> = ({
  contentPath,
  guideTabs,
  deletedLocation,
  openCreateListModal,
}) => {
  return (
    <Swiper
      className="empty-shopping-list-slider"
      modules={[Pagination, Navigation]}
      pagination={{
        clickable: true,
      }}
      longSwipesMs={0}
      navigation
      loop
    >
      {guideTabs?.map(({ icon, description, title }) => {
        return (
          <SwiperSlide key={icon.url}>
            <div className="empty-shopping-list-slider__wrapper">
              <div className="empty-shopping-list-slider__content">
                <h2>
                  <ContentstackText contentKey={`${contentPath}.mobile_title`} />
                </h2>
                <p>
                  <ContentstackText contentKey={`${contentPath}.mobile_description`} />
                </p>
              </div>
              <div className="empty-shopping-list-slider__guide-tiles">
                <div className="grid-container grid-x small-10 align-center">
                  <div className="empty-shopping-list-slider__guide-tiles__icon">
                    <img src={icon.url} alt={title} />
                  </div>
                  <div
                    className="empty-shopping-list-slider__guide-tiles__description small-8"
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                  />
                </div>
              </div>
              <div className="empty-shopping-list-slider__button">
                <CorButton color="tertiary" disabled={deletedLocation} onClick={openCreateListModal}>
                  <ContentstackText contentKey={`${contentPath}.create_list_button`} />
                </CorButton>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
