import React, { FC, createContext, PropsWithChildren, useCallback } from 'react';
import { merge, isEmpty } from 'lodash-es';
import { ICSGlobalFacets } from 'corporate-interfaces';
import { GlobalFacetsBlackList } from 'corporate-constants/global-facets.constants';
import { useContentstack } from 'hooks/use-contentstack';
import { useContentstackSearchUrl } from 'hooks/use-contentstack-search-url';
import { IContentstackParams } from 'routes/routes';
import { isInternationalSite } from 'utils/get-locale-params';
import { useLocalStorage } from 'hooks/use-local-storage';
import { ICSUtilityNavData } from '../components/layout/cor-header/cor-header.interface';
import { useContentstackSearchTags } from '../hooks/use-contentstack-search-tags';

export const ContentstackContext = createContext<null | {}>(null);

export interface IContentstackProvider extends PropsWithChildren {
  contentstackParams: IContentstackParams | undefined;
  contentstackNotFoundParams: IContentstackParams | undefined;
  additionalContentTypeToFetch?: { id: string; pathToContent: string; include: string[] }[];
}

export const ContentstackProvider: FC<IContentstackProvider> = ({
  children,
  contentstackParams,
  contentstackNotFoundParams,
  additionalContentTypeToFetch,
}) => {
  let content = useContentstack({ contentstackParams, contentstackNotFoundParams });
  const [locale] = useLocalStorage('locale', null);

  const globalFacetsData = useContentstackSearchUrl({
    contentTypeName: 'global_facets',
    url: '/global-facets',
    ...(contentstackParams?.locale &&
      isInternationalSite(contentstackParams?.locale) && { locale: contentstackParams.locale }),
  });
  const globalFacets = globalFacetsData.content as ICSGlobalFacets;

  const additionalContent: ICSUtilityNavData[] = useContentstackSearchTags(
    additionalContentTypeToFetch?.map((additionalContentTypeItem) => ({
      contentstackParams: additionalContentTypeItem.id,
      include: additionalContentTypeItem.include,
      contentTypeName: additionalContentTypeItem.id,
      ...(isInternationalSite(locale) && { locale }),
    })),
    true
  );

  const getContentObjectFromContentPath = (contentPath, value) => {
    const contentKeys = contentPath.split(/[.[\]]/g).filter((key) => key);

    return contentKeys.reduceRight((prev, curr) => {
      if (isNaN(curr)) {
        return { [curr]: prev };
      } else {
        let entryArray = new Array(parseInt(curr) + 1);
        entryArray[parseInt(curr)] = prev;
        return entryArray;
      }
    }, value);
  };

  const mergeContent = useCallback(() => {
    if (isEmpty(additionalContent) || !additionalContentTypeToFetch || !additionalContent?.length) {
      return content;
    }

    const result = additionalContentTypeToFetch.map((additionalContentTypeItem) => {
      const value = additionalContent.find(
        (additionalContentValue) => additionalContentTypeItem.id in additionalContentValue
      );
      if (value) {
        return getContentObjectFromContentPath(
          additionalContentTypeItem.pathToContent,
          value[additionalContentTypeItem.id]
        );
      }
      return getContentObjectFromContentPath(additionalContentTypeItem.pathToContent, additionalContent[0]);
    });

    return merge({}, content, ...result);
  }, [content, additionalContent, additionalContentTypeToFetch]);

  return (
    <ContentstackContext.Provider
      value={{
        ...mergeContent(),
        contentTypeName: contentstackParams?.contentTypeName,
        globalFacets: {
          ...globalFacets,
          page_types: globalFacets?.page_types?.filter((pageType) => !GlobalFacetsBlackList.has(pageType.type)),
        },
      }}
    >
      {children}
    </ContentstackContext.Provider>
  );
};
