import { FC } from 'react';
import { ContentstackText } from 'components/contentstack';

import './product-new-arrival-badge.scss';

export interface IProductNewArrivalBadgeProp {
  contentKey: string;
}

export const ProductNewArrivalBadge: FC<IProductNewArrivalBadgeProp> = ({ contentKey }) => {
  return (
    <span className="product-new-arrival-badge">
      <ContentstackText contentKey={contentKey} />
    </span>
  );
};
