import React, { useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import api from 'api';
import qs from 'qs';
import { Endpoints } from 'constants/endpoints.enum';
import { STATIC_PAGES } from 'constants/static-pages.enum';
import { ILocationParams } from 'components/router/route-wrapper';
import { Routes } from 'constants/routes.enum';
import { checkIfShopUrlValid } from 'utils/check-if-shop-url-valid';
import { checkIfAccountUrlValid } from 'utils/check-if-account-url-valid';
import { IContentstackParams } from 'routes/routes';
import { CS_CACHED_CONTENT_PREFIX } from 'utils/clear-cached-contentstack-content';

interface IContentstackEntry {
  locale: string;
  uid: string;
}
interface IContentstackProps {
  contentstackParams?: IContentstackParams;
  contentstackNotFoundParams?: IContentstackParams;
}

export const useContentstack = ({ contentstackParams, contentstackNotFoundParams }: IContentstackProps) => {
  const [content, setContent] = useState({});
  const [isNotFoundPage, setIsNotFoundPage] = React.useState(false);
  const { pathname: locationPathname } = useLocation();
  const { lang, country }: ILocationParams = useParams();

  const handleApiCall = React.useCallback(
    async ({ dynamicParams, fallbackCall = () => {} }) => {
      if (!dynamicParams?.contentTypeName || !dynamicParams?.url || !contentstackParams?.contentTypeName) {
        return;
      }

      const cachedContent = sessionStorage.getItem(`${CS_CACHED_CONTENT_PREFIX}-${contentstackParams.contentTypeName}`);
      if (cachedContent) {
        const parsedContent = JSON.parse(cachedContent);
        updateChromeAddonMetadata(`${CS_CACHED_CONTENT_PREFIX}-${dynamicParams?.contentTypeName}` || '', parsedContent);
        setContent(parsedContent);
        return;
      }

      try {
        const {
          data: [pageContent],
        } = await api.get(
          `${Endpoints.CONTENTSTACK_URLS}?${qs.stringify({
            params: dynamicParams?.url || '',
            contentTypeName: dynamicParams?.contentTypeName || '',
            include: dynamicParams?.include || [''],
            ...(dynamicParams?.locale && { locale: dynamicParams?.locale }),
          })}`
        );

        if (pageContent) {
          if (locationPathname.includes(Routes.ShopHomePage)) {
            sessionStorage.setItem(
              `${CS_CACHED_CONTENT_PREFIX}-${dynamicParams?.contentTypeName}`,
              JSON.stringify(pageContent)
            );
          }
          updateChromeAddonMetadata(dynamicParams?.contentTypeName || '', pageContent);
          setContent(pageContent);
          return;
        }
        fallbackCall();
      } catch (error) {
        const { response } = error as { response: Response };

        if (![401, 403].includes(response?.status)) {
          console.error(error);
          window.location.assign(STATIC_PAGES.ServiceUnavailablePage);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentstackParams?.contentTypeName]
  );

  React.useEffect(() => {
    if (locationPathname.includes(Routes.ShopHomePage) && contentstackNotFoundParams) {
      const isValidUrl = checkIfShopUrlValid(lang, country);
      const isAccountPathnameValid = checkIfAccountUrlValid(locationPathname);
      if (!isValidUrl || !isAccountPathnameValid) {
        setIsNotFoundPage(true);
        handleApiCall({ dynamicParams: contentstackNotFoundParams });
        return;
      }
    }

    if (!contentstackParams?.contentTypeName || !contentstackParams?.url) return;

    setIsNotFoundPage(false);

    handleApiCall({
      dynamicParams: contentstackParams,
      fallbackCall: () => {
        handleApiCall({ dynamicParams: contentstackNotFoundParams });
        setIsNotFoundPage(true);
      },
    });
  }, [contentstackNotFoundParams, contentstackParams, handleApiCall, locationPathname, lang, country]);

  return useMemo(() => ({ ...content, c_is_not_found_page: isNotFoundPage }), [content, isNotFoundPage]);
};

const updateChromeAddonMetadata = (contentType: string, pageContent: IContentstackEntry) => {
  // for https://chrome.google.com/webstore/detail/contentstack/kliacmaffhaakhphcelpjcmienmikgoa?hl=en

  try {
    document.body.setAttribute('data-pageref', pageContent.uid);
    document.body.setAttribute('data-locale', pageContent.locale);
    document.body.setAttribute('data-contenttype', contentType);
  } catch (e) {
    console.warn('failed to update contentstack metadata on document', e);
  }
};
