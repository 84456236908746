import { useCallback } from 'react';
import { IAddCreditCardValues } from 'components/forms/add-payment-card-form';

export interface IOnFieldEventHandler {
  setupComplete?: () => void;
  threeDsChallengeExecuted?: () => void;
  onFocus?: (tagId: string) => void;
  onBlur?: (tagId: string) => void;
  onError?: (tagId: string, errorCode: string, errorDescription: string) => void;
  onType?: (tagId: string, cardType: string, cardData: string) => void;
  onEnter?: (tagId: string) => void;
  onValid?: (tagId: string) => void;
}

export interface IBlueSnapObject {
  token?: string;
  onFieldEventHandler: IOnFieldEventHandler;
  style?: Object;
  ccnPlaceHolder: string;
  cvvPlaceHolder: string;
  expPlaceHolder: string;
}
export interface IBlueSnapCallBack {
  onFailCallBack: (callback) => void;
  onSuccessCallBAck: (values: IAddCreditCardValues) => void;
}

export const useBlueSnapHostedPaymentFields = (bsObj: IBlueSnapObject, token: string) => {
  const createHostedPaymentFields = useCallback(() => {
    if (token) {
      bluesnap.hostedPaymentFieldsCreate({ ...bsObj, token });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const submitHostedPaymentFieldsData = (
    onFailCallBack: (callback) => void,
    onSuccessCallBAck: (values: IAddCreditCardValues) => void,
    values: IAddCreditCardValues
  ) => {
    bluesnap.hostedPaymentFieldsSubmitData(function (callback) {
      if (null != callback.error) {
        onFailCallBack(callback);
      } else {
        onSuccessCallBAck(values);
      }
    });
  };

  return {
    createHostedPaymentFields,
    submitHostedPaymentFieldsData,
  };
};
