import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getPaymentFieldToken } from 'store/payment-field-token/actions';
import { IGetPaymentFieldTokenPayload } from 'store/payment-field-token/sagas/get-payment-field-token';

export const useGetBlueSnapToken = (onBlueSnapErrorCallBack: () => void) => {
  const dispatch = useDispatch();
  const getPaymentToken = useCallback(() => {
    dispatch(
      getPaymentFieldToken.request<IGetPaymentFieldTokenPayload>({
        onFailCallBack: onBlueSnapErrorCallBack,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  return {
    getPaymentToken,
  };
};
