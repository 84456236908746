import React, { FC, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { findIndex } from 'lodash-es';
import { CorSalesRep } from 'corporate-components/cor-layout/cor-sales-rep';
import { useMainNavState } from 'components/layout/cor-header/context/headerContext';
import {
  IAllProductsCategory,
  IMainMenuItem,
  ICSHeaderCorpNavMenuOut,
  IMenuItemProps,
  IUtilityNavUserInfo,
} from 'components/layout/cor-header/cor-header.interface';
import { ICategory } from 'store/categories-navigation/reducer';
import { useEnvironment } from 'hooks/use-environment';
import { IconsMenu, MainNavItem, MainNavItemSubnav, UtilityNavSubmenu } from 'components/layout/cor-header/components';
import { useNavigationStateContext } from 'hooks/use-navigation-state-context';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { Routes } from 'constants/routes.enum';
import { ALL_PRODUCTS_KEYS, ALL_PRODUCTS_LABELS } from 'components/layout/cor-header/cor-header.constants';
import { selectIsAuthorised, selectIsImpersonateModeOn, selectIsInvoiceOnlyCustomer } from 'store/auth/selectors';
import { selectNewArrivalVisibility } from 'store/categories-navigation/selectors';
import {
  getHeaderNotificationsClass,
  handleOpenMenuBodyStyling,
} from 'components/layout/cor-header/utilities/handle-menus';
import { useLocalStorage } from 'hooks/use-local-storage';
import { isInternationalSite } from 'utils/get-locale-params';
import { logOutClickHandler } from 'utils/link-utils';
import { selectCustomerLocations } from 'store/customer-login/selectors';
import iconMyAccount from 'assets/images/icons/icon-my-account.svg';
import iconChevronRight from 'assets/images/icons/icon-caret-grey-right.svg';
import { useNotificationsListModal } from 'hooks/use-global-modal';
import { useGlobalModalState } from 'hooks/use-global-modal-state';
import { selectCustomerNotifications } from 'store/notifications/selectors';
import { ICustomerNotificationItem } from 'types/notifications';
import { INavHeaderLabels } from 'components/layout/cor-header/cor-header';

import './main-nav.scss';

export interface IMainMenuProps {
  corporateMenu: ICSHeaderCorpNavMenuOut;
  shopMenu?: ICSHeaderCorpNavMenuOut;
  utilityItems: IMenuItemProps[];
  labels: INavHeaderLabels;
  userInfo?: IUtilityNavUserInfo;
}

export interface ICategoryWithOptionalURL extends ICategory {
  url?: string;
}

export const MainCorporateNav: FC<any> = ({
  showCorporateNav,
  corporateMenu,
  level,
  isAuthenticated,
  closeHamburgerMenuHandler,
}) => {
  const { isMobile, isDesktop } = useBreakpoint();
  const { shopNavOpen } = useMainNavState();
  const corporateMenuRef = useRef<CSSTransition>(null);
  const corporateMenuDivRef = useRef<HTMLDivElement>(null);

  const filteredMenuItems = corporateMenu.filter((item) => isMobile || !item.mobileOnly);

  return (
    <CSSTransition
      key={'corporate-menu-nav'}
      ref={corporateMenuRef}
      in={!shopNavOpen}
      timeout={0}
      classNames="corporate-menu"
    >
      <div
        ref={corporateMenuDivRef}
        className={classNames('main-nav__menu', 'main-nav__menu--corporate', {
          authenticated: isAuthenticated || isDesktop,
          'is-open': showCorporateNav,
        })}
      >
        {filteredMenuItems &&
          !!filteredMenuItems.length &&
          filteredMenuItems.map((menu, index) => {
            const isFirst = index === 0;
            const isLast = index === filteredMenuItems.length - 1;
            return (
              <MainNavItem
                name={`${menu.url}-${index}-menu`}
                label={menu.label}
                url={menu.url}
                hasSubmenu={
                  !!menu.children.length || !!menu.resourceMenus.length || !!Object.keys(menu.drillDownMenu).length
                }
                key={index}
                position={isFirst ? 'first' : isLast ? 'last' : null}
                level={level}
                closeHamburgerMenuHandler={() => closeHamburgerMenuHandler(menu?.url)}
              >
                <MainNavItemSubnav
                  parent={`${menu.url}-${index}-menu`}
                  label={menu.label}
                  childItems={menu.children}
                  resourceMenus={menu.resourceMenus}
                  drillDownMenu={menu.drillDownMenu}
                  menuId={menu.menuId}
                  closeHamburgerMenuHandler={closeHamburgerMenuHandler}
                />
              </MainNavItem>
            );
          })}
      </div>
    </CSSTransition>
  );
};

export const MainNav: FC<IMainMenuProps> = ({ corporateMenu, shopMenu, utilityItems, labels, userInfo }) => {
  const { isMobile } = useBreakpoint();
  const { isProduction } = useEnvironment();
  const isImpersonateModeOn = useSelector(selectIsImpersonateModeOn);
  const { shopNavOpen, isLoggedInUser, mobileMenuOpen, setMobileMenuOpen, setShopNavOpen } = useMainNavState();
  const shopMenuRef = useRef<CSSTransition>(null);
  const shopMenuDivRef = useRef<HTMLDivElement>(null);
  const showCorporateNav: boolean = !isLoggedInUser || !shopNavOpen;
  const { visibleCategories } = useNavigationStateContext();
  const showNewArrivals = useSelector(selectNewArrivalVisibility);
  const customerLocations = useSelector(selectCustomerLocations);
  const { hideGlobalModal, modal } = useGlobalModalState();
  const notifications: ICustomerNotificationItem[] = useSelector(selectCustomerNotifications);
  const isInvoiceOnlyUser = useSelector(selectIsInvoiceOnlyCustomer);
  const isUserAuthorized = useSelector(selectIsAuthorised);

  const [locale] = useLocalStorage('locale', null);
  const dispatch = useDispatch();
  const [currentOpenUrl, setCurrentOpenUrl] = useState(decodeURI(window.location.pathname));

  const [panelIsOpen, setPanelIsOpen] = useState<boolean>(false);

  const userMenuData: IMenuItemProps = {
    url: isLoggedInUser ? '#' : Routes.LoginPage,
    image: userInfo?.image,
    menuPanelItems: userInfo?.menuOptions?.map((loc) => {
      const children = loc.url === Routes.AccountLocationsPage ? customerLocations : [];
      return { ...loc, children };
    }),
    isHiddenInMobileStickyHeader: true,
    menuPanelTitle: userInfo?.text,
  };

  useEffect(() => {
    handleOpenMenuBodyStyling({ overflow: panelIsOpen ? 'hidden' : 'auto' });
  }, [panelIsOpen]);

  const menuPanelRef = useRef<CSSTransition>(null);
  const menuPanelDivRef = useRef<HTMLDivElement>(null);

  const newArrivalItem: any = {
    categoryKey: ALL_PRODUCTS_KEYS.NEW_ARRIVALS,
    categoryName: shopMenu?.new_arrivals_link?.title || ALL_PRODUCTS_LABELS.NEW_ARRIVALS,
    breadcrumbs: [
      {
        key: shopMenu?.new_arrivals_link?.href,
        name: shopMenu?.new_arrivals_link?.title || ALL_PRODUCTS_LABELS.NEW_ARRIVALS,
      },
    ],
    children: [],
    count: 0,
    url: shopMenu?.new_arrivals_link?.href,
  };

  const getNotificationItemUrl = (url) => {
    if (url) {
      setCurrentOpenUrl(url);
    }
  };

  const showNotificationListModal = useNotificationsListModal({
    notifications: notifications,
    notificationItemUrl: getNotificationItemUrl,
    onClose: () => {
      hideGlobalModal();
      if (isMobile) {
        closeHamburgerMenuHandler(currentOpenUrl);
        handleOpenMenuBodyStyling({ overflow: 'auto' });
      }
    },
  });

  const transposeMenuItems = (): ICategoryWithOptionalURL[] => {
    const addlProductsIndex = findIndex(
      visibleCategories,
      (category) => category.categoryKey === ALL_PRODUCTS_KEYS.ADDITIONAL_PRODUCTS
    );
    if (addlProductsIndex === -1) {
      return [];
    }

    const addlProducts = visibleCategories[addlProductsIndex];
    const rearranged = visibleCategories.slice();
    const newArrivals = showNewArrivals ? [newArrivalItem] : [];

    rearranged.splice(addlProductsIndex, 1);

    return [...rearranged, ...newArrivals, addlProducts];
  };

  // Products Menu gets interactive drill-down submenu...
  const fullShopMenu: any =
    isLoggedInUser && shopMenu
      ? shopMenu.links.map((menu: IMainMenuItem, index) => {
          return menu?.url?.toLowerCase() === Routes.ProductListPage
            ? {
                ...menu,
                menuId: `shopMenu${index}`,
                drillDownMenu: {
                  ...menu.drillDownMenu,
                  items: (transposeMenuItems() as unknown) as IAllProductsCategory[],
                },
              }
            : {
                ...menu,
                menuId: `shopMenu${index}`,
              };
        })
      : null;
  // not auth
  // filter menu items for displaying in the icons menu...
  const filteredUtilityItems = utilityItems?.filter(
    (utilityItem) => !utilityItem.showInNav || utilityItem.showInMobileNav
  );

  const handleLogOutClick = React.useCallback(
    (e) => {
      if (!isImpersonateModeOn) {
        logOutClickHandler(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, isImpersonateModeOn]
  );

  const handleNotificationsPanelClick = () => {
    if (modal.isOpen) {
      hideGlobalModal();
      closeHamburgerMenuHandler(currentOpenUrl);
      return;
    }
    showNotificationListModal();
    isMobile && handleOpenMenuBodyStyling({ overflow: 'hidden' });
  };

  const closeHamburgerMenuHandler = (url) => {
    if (isMobile && currentOpenUrl.includes(url)) {
      mobileMenuOpen && setMobileMenuOpen(false);
      shopNavOpen && setShopNavOpen(false);
    }
  };
  return (
    <nav
      className={classNames('main-nav', getHeaderNotificationsClass(isProduction, isImpersonateModeOn), {
        'is-logged': isLoggedInUser,
      })}
      aria-label={'Main Nav'}
    >
      <div className={`main-nav__container${!showCorporateNav ? ' is-shop-nav' : ''}`}>
        {isMobile && isLoggedInUser && (
          <IconsMenu
            items={filteredUtilityItems}
            handleNotificationsPanelClick={handleNotificationsPanelClick}
            closeHamburgerMenuHandler={closeHamburgerMenuHandler}
          />
        )}
        {isMobile && !isInternationalSite(locale) && (
          <div className={'main-nav__menu--shop-button'}>
            <MainNavItem
              name="shop-button-mobile"
              label={labels?.shop_label}
              url={isInvoiceOnlyUser && isUserAuthorized ? Routes.AccountPayments : Routes.ShopHomePage}
              hasSubmenu={false}
              level={1}
              closeHamburgerMenuHandler={() => closeHamburgerMenuHandler(Routes.ShopHomePage)}
            />
          </div>
        )}
        {isLoggedInUser && (
          <CSSTransition key={'shop-menu-nav'} ref={shopMenuRef} in={shopNavOpen} timeout={0} classNames="shop-menu">
            <div
              ref={shopMenuDivRef}
              className={`main-nav__menu${shopNavOpen ? ' is-open ' : ' '}main-nav__menu--shop`}
            >
              {fullShopMenu?.map((menu, index) => {
                const isFirst = index === 0;
                const isLast = index === fullShopMenu.length - 1;

                return (
                  <MainNavItem
                    name={`${menu.url}-${index}-menu`}
                    label={menu.label}
                    url={menu.url}
                    hasSubmenu={
                      !!menu.children.length || !!menu.resourceMenus.length || !!Object.keys(menu.drillDownMenu).length
                    }
                    position={isFirst ? 'first' : isLast ? 'last' : null}
                    key={index}
                    level={1}
                    closeHamburgerMenuHandler={() => closeHamburgerMenuHandler(menu?.url)}
                  >
                    <MainNavItemSubnav
                      parent={`${menu.url}-${index}-menu`}
                      url={menu.url}
                      label={menu.label}
                      childItems={menu.children}
                      resourceMenus={menu.resourceMenus}
                      drillDownMenu={menu.drillDownMenu}
                      menuId={menu.menuId}
                      closeHamburgerMenuHandler={closeHamburgerMenuHandler}
                    />
                  </MainNavItem>
                );
              })}
            </div>
          </CSSTransition>
        )}
        <div className={'main-nav--corporate'}>
          {isMobile && isLoggedInUser ? (
            <>
              <div>
                <button className="main-nav__my-account-menu" onClick={() => setPanelIsOpen(true)}>
                  <img src={iconMyAccount} alt="my account" />
                  <span className="main-nav__my-account-menu-btn-text">{userMenuData?.menuPanelTitle}</span>
                  <img src={iconChevronRight} className="main-nav__my-account-menu-chevron-icon" alt="arrow right" />
                </button>
                <CSSTransition
                  ref={menuPanelRef}
                  in={panelIsOpen}
                  unmountOnExit
                  timeout={0}
                  classNames="utility-menu-panel-container"
                >
                  <div ref={menuPanelDivRef} className="utility-menu-panel-container">
                    <UtilityNavSubmenu
                      onClickHandler={() => setPanelIsOpen(false)}
                      menuPanelItems={userMenuData?.menuPanelItems}
                      menuPanelTitle={userMenuData?.menuPanelTitle}
                      onLogOut={handleLogOutClick}
                      closeHamburgerMenuHandler={closeHamburgerMenuHandler}
                    />
                  </div>
                </CSSTransition>
              </div>
              <MainNavItem
                name="corporate-mobile-nav"
                label={corporateMenu.label || ''}
                url={'#'}
                hasSubmenu={true}
                level={1}
                customClass="mobile-corporate-menu"
                closeHamburgerMenuHandler={() => {
                  fullShopMenu?.menuPanelItems?.forEach((menuItem) => {
                    if (isMobile && currentOpenUrl.includes(menuItem?.url)) {
                      mobileMenuOpen && setMobileMenuOpen(false);
                      shopNavOpen && setShopNavOpen(false);
                    }
                  });
                }}
              >
                <MainCorporateNav
                  showCorporateNav={showCorporateNav}
                  corporateMenu={corporateMenu.links}
                  level={2}
                  isAuthenticated={true}
                  closeHamburgerMenuHandler={closeHamburgerMenuHandler}
                />
              </MainNavItem>
            </>
          ) : (
            <MainCorporateNav
              showCorporateNav={showCorporateNav}
              corporateMenu={corporateMenu.links}
              level={1}
              isAuthenticated={false}
              closeHamburgerMenuHandler={closeHamburgerMenuHandler}
            />
          )}
        </div>
        {isMobile && isLoggedInUser && (
          <div className="main-nav__menu__sales-rep">
            <CorSalesRep showPhone={true} isHeader={true} />
          </div>
        )}
      </div>
    </nav>
  );
};
