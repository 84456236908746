import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { COR_NAV_ORANGE } from 'corporate-ui/cor-constants';
import { CorContentstackLink } from 'corporate-components';
import { selectIsHouseAccountUser, selectIsImpersonateModeOn, selectIsTsrUser } from 'store/auth/selectors';
import { UtilityLocations } from 'components/layout/cor-header/components/utility-navigation/utility-locations';
import { IMainMenuLink } from 'components/layout/cor-header/cor-header.interface';
import { useOutsideClick } from 'hooks/use-outside-click';
import { IconMenuArrow } from 'components/icons/icon-menu-arrow';
import { selectIsHeaderCollapsed } from 'store/ui/selectors';
import { Routes } from 'constants/routes.enum';
import { isExternalUrl } from 'utils/is-external-url';
import { logOutClickHandler } from 'utils/link-utils';
import { useGetUserRole } from 'hooks/use-get-user-role';

import './utility-nav-desktop-menu.scss';

export interface IMenuDropdownProps {
  label: string;
  panelContent?: IMainMenuLink[];
  className?: string;
  panelTitle?: string;
  icon?: string;
}

export const UtilityNavDesktopMenu: FC<IMenuDropdownProps> = (props) => {
  const isImpersonateModeOn = useSelector(selectIsImpersonateModeOn);
  const isTsrUser = useSelector(selectIsTsrUser);
  const isHeaderCollapsed = useSelector(selectIsHeaderCollapsed);
  const isHouseAccount = useSelector(selectIsHouseAccountUser);
  const dispatch = useDispatch();
  const innerRef = useRef(null);
  const { label, panelContent, panelTitle, className, icon } = props;
  const [opened, setOpened] = useState(false);
  const handleDropdownClick = React.useCallback(() => setOpened(!opened), [opened]);
  const [locationPanelOpen, setLocationPanelOpen] = useState(false);
  const { isSuperAdminOrAdmin } = useGetUserRole();

  const handleLogOutClick = React.useCallback(
    (e) => {
      if (isImpersonateModeOn) {
        e.preventDefault();
        return;
      }
      logOutClickHandler(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, isImpersonateModeOn]
  );

  useOutsideClick(innerRef, opened, handleDropdownClick, true);

  useEffect(() => {
    if (opened && isHeaderCollapsed) {
      setOpened(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHeaderCollapsed]);

  return (
    <div className={classNames('menu-dropdown', className)} ref={innerRef}>
      <button className={classNames('menu-dropdown__button', { 'is-active': opened })} onClick={handleDropdownClick}>
        <span className="hint-text">{label}</span>
        {icon && <img src={icon} className={'menu-dropdown__icon'} alt={label || ''} />}
        <span className={'menu-dropdown__label'}>{label}</span>
        <IconMenuArrow dir={opened ? 'down' : 'up'} color={COR_NAV_ORANGE} height={15} />
      </button>

      {opened && (
        <div className={'menu-dropdown__panel'}>
          {panelTitle && <div className={'menu-dropdown__title'}>{panelTitle}</div>}
          {panelContent && panelContent.length > 0 && (
            <ul className={'menu-dropdown__panel-list'}>
              {panelContent.map((item, index) => {
                const isLocations = item.url === Routes.AccountLocationsPage;
                const isPayments = item?.url?.includes(Routes.AccountPayments);

                if (isPayments && (isTsrUser || (!isSuperAdminOrAdmin && isHouseAccount))) {
                  return null;
                }

                return (
                  <li className={'menu-dropdown__panel-item'} key={index}>
                    <CorContentstackLink
                      contentKey=""
                      data={{ href: item.url, title: item.label }}
                      className={'menu-dropdown__panel-link'}
                      onClick={(e: React.SyntheticEvent<HTMLAnchorElement>) =>
                        item.url === Routes.LoginPage ? handleLogOutClick(e) : false
                      }
                      aria-label={item.label}
                      opensInNewTab={item?.url ? isExternalUrl(item?.url) : false}
                    >
                      {item.label}
                    </CorContentstackLink>
                    {isLocations && (
                      <>
                        <div
                          className={classNames({
                            'user-locations-panel-popover': isLocations,
                            'is-open': locationPanelOpen,
                          })}
                          tabIndex={0}
                          role="button"
                          onMouseOver={() => setLocationPanelOpen(true)}
                          onMouseOut={() => setLocationPanelOpen(false)}
                        >
                          <IconMenuArrow
                            dir={locationPanelOpen ? 'down' : 'right'}
                            color={COR_NAV_ORANGE}
                            height={15}
                          />
                          <UtilityLocations />
                        </div>
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};
