import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { BuyItAgainSkusSliderItem } from 'features/order-updates/components/buy-it-again-skus-slider-item';
import { selectBuyItAgainProducts } from 'store/home/selectors';

import './buy-it-again-skus-slider.scss';

export interface IBuyItAgainSkusSliderProps {
  onRequestQuoteCtaClick?: (skuKey: string, isOpenedWithinQuickShop: boolean) => void;
}

export const BuyItAgainSkusSlider: React.FC<IBuyItAgainSkusSliderProps> = ({ onRequestQuoteCtaClick }) => {
  const buyItAgainProducts = useSelector(selectBuyItAgainProducts);

  return (
    <Swiper
      className={classNames('buy-it-again-skus-slider', {
        'buy-it-again-skus-slider--single-order': buyItAgainProducts?.length === 1,
      })}
      modules={[Pagination, Navigation]}
      pagination={{
        clickable: true,
      }}
      longSwipesMs={0}
      navigation
      loop
    >
      {buyItAgainProducts?.map((item, index) => (
        <SwiperSlide key={item.number}>
          <BuyItAgainSkusSliderItem index={index} onRequestQuoteCtaClick={onRequestQuoteCtaClick} {...item} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
