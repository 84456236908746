import React, { FC, useState } from 'react';
import { CorContentstackLink } from 'corporate-components';
import { useSelector } from 'react-redux';
import { COR_NAV_ORANGE } from 'corporate-ui/cor-constants';
import classNames from 'classnames';
import { IMainMenuLink } from 'components/layout/cor-header/cor-header.interface';
import { UtilityLocations } from 'components/layout/cor-header/components/utility-navigation';
import { MobilePanelCloser } from 'components/layout/cor-header/components';
import { IconMenuArrow } from 'components/icons/icon-menu-arrow';
import { HEADER_STATIC_LABELS } from 'components/layout/cor-header/cor-header.constants';
import { isExternalUrl } from 'utils/is-external-url';
import { Routes } from 'constants/routes.enum';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { selectIsHouseAccountUser } from 'store/auth/selectors';
import { useGetUserRole } from 'hooks/use-get-user-role';

import './utility-nav-submenu.scss';

export interface IMenuPanelProps {
  arialLabel?: string;
  menuPanelTitle?: string;
  onClickHandler?: ($event) => void;
  menuPanelItems?: IMainMenuLink[];
  onLogOut?: (e: React.SyntheticEvent<HTMLAnchorElement>) => void;
  closeHamburgerMenuHandler?: (url: string) => void;
}

export const UtilityNavSubmenu: FC<IMenuPanelProps> = (props) => {
  const { arialLabel, onClickHandler, menuPanelItems, menuPanelTitle, onLogOut, closeHamburgerMenuHandler } = props;
  const [locationPanelOpen, setLocationPanelOpen] = useState<boolean>(false);
  const isHouseAccount = useSelector(selectIsHouseAccountUser);
  const { isMobile } = useBreakpoint();
  const { isSuperAdminOrAdmin } = useGetUserRole();
  const menuOptionsWithoutPayments = menuPanelItems?.filter((item) => item.url !== Routes.AccountPayments);
  const menuPanelDropdownItems = isSuperAdminOrAdmin ? menuPanelItems : menuOptionsWithoutPayments;

  const toggleLocations = (event) => {
    if (event.target.nodeName === 'A') {
      return;
    }
    setLocationPanelOpen(!locationPanelOpen);
  };

  const RenderLink = ({ menuPanelItem, customClass, closeHamburgerMenuHandler }) => {
    return !!menuPanelItem.url ? (
      <CorContentstackLink
        data-testid={customClass}
        contentKey={''}
        data={{ href: menuPanelItem.url, title: menuPanelItem.label }}
        onClick={(e: React.SyntheticEvent<HTMLAnchorElement>) => {
          if (menuPanelItem.url === Routes.LoginPage && onLogOut) {
            onLogOut(e);
          }
          if (isMobile && window.location.pathname === menuPanelItem?.url) {
            onClickHandler && onClickHandler(e);
            closeHamburgerMenuHandler(menuPanelItem?.url);
          }
        }}
        aria-label={menuPanelItem.label}
        opensInNewTab={menuPanelItem?.url ? isExternalUrl(menuPanelItem.url) : false}
      >
        {menuPanelItem.label}
      </CorContentstackLink>
    ) : (
      <div
        data-testid={customClass}
        className={customClass}
        dangerouslySetInnerHTML={{ __html: menuPanelItem.label }}
      />
    );
  };

  return (
    <div className={'menu-panel'} aria-label={arialLabel || ''}>
      <MobilePanelCloser
        label={menuPanelTitle || HEADER_STATIC_LABELS.ACCOUNT}
        handleMenuOpen={(event) => onClickHandler && onClickHandler(event)}
      />

      {!!menuPanelDropdownItems && (
        <div className={'menu-panel__scroll'}>
          <ul className={'menu-panel__items'}>
            {menuPanelDropdownItems?.map((menuPanelItem, index) => {
              const panelSubmenu = menuPanelItem.children || null;

              if (menuPanelItem.url?.includes(Routes.AccountPayments) && !isSuperAdminOrAdmin && isHouseAccount) {
                return null;
              }

              return (
                <li
                  key={index}
                  className={classNames('menu-panel__item', { 'has-subnav': panelSubmenu && !!panelSubmenu.length })}
                >
                  {/*if there is a submenu... */}
                  {!!menuPanelItem?.children?.length && (
                    <>
                      <div
                        className="menu-panel__subitems"
                        onClick={(event) => toggleLocations(event)}
                        tabIndex={0}
                        role="button"
                      >
                        <RenderLink
                          menuPanelItem={menuPanelItem}
                          closeHamburgerMenuHandler={closeHamburgerMenuHandler}
                          customClass={'main-subnav-content-link'}
                        />
                        <IconMenuArrow dir={locationPanelOpen ? 'down' : 'up'} color={COR_NAV_ORANGE} height={15} />
                      </div>
                      {locationPanelOpen && <UtilityLocations />}
                    </>
                  )}
                  {/*if there is not a submenu... */}
                  {!menuPanelItem?.children?.length && (
                    <RenderLink
                      menuPanelItem={menuPanelItem}
                      closeHamburgerMenuHandler={closeHamburgerMenuHandler}
                      customClass={'main-subnav-content-link'}
                    />
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
