import React, { createContext, useState } from 'react';
import { ICSUtilityNavData } from 'components/layout/cor-header/cor-header.interface';

export interface IOpenedMenu {
  [key: string]: unknown[];
}

export interface IHeaderContext {
  shopNavOpen: boolean;
  mobileMenuOpen: boolean;
  openedMenu: IOpenedMenu;
  isLoggedInUser: boolean;
  setShopNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenedMenu: React.Dispatch<React.SetStateAction<IOpenedMenu>>;
  setIsSearchMobileOpen: (isOpen: boolean) => void;
  isSearchMobileOpen?: boolean;
  setMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoggedInUser: React.Dispatch<React.SetStateAction<boolean>>;
  header: ICSUtilityNavData;
}

const HeaderContext = createContext<IHeaderContext | undefined>(undefined);

function HeaderProvider(props) {
  const { children, header } = props;
  const [shopNavOpen, setShopNavOpen] = useState<boolean>(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [openedMenu, setOpenedMenu] = useState<IOpenedMenu>({});
  const [isSearchMobileOpen, setIsSearchMobileOpen] = useState<boolean>(false);
  const [isLoggedInUser, setIsLoggedInUser] = useState<boolean>(false);

  const value = {
    shopNavOpen,
    setShopNavOpen,
    mobileMenuOpen,
    setMobileMenuOpen,
    openedMenu,
    setOpenedMenu,
    isLoggedInUser,
    setIsLoggedInUser,
    setIsSearchMobileOpen,
    isSearchMobileOpen,
    header,
  };
  return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>;
}

function useMainNavState() {
  const context = React.useContext(HeaderContext);
  if (context === undefined) {
    throw new Error('useMainNavState must be used within a HeaderProvider');
  }
  return context;
}

export { HeaderProvider, useMainNavState };
