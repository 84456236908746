import React, { FC, useEffect, useRef, useState, RefObject, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { CorContentstackLink } from 'corporate-components';
import { useNotificationsListModal } from 'hooks/use-global-modal';
import { useGlobalModalState } from 'hooks/use-global-modal-state';
import { Routes } from 'constants/routes.enum';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { IMenuItemProps } from 'components/layout/cor-header/cor-header.interface';
import { selectIsTsrUser } from 'store/auth/selectors';
import { HeaderIcon, UtilityNavSubmenu } from 'components/layout/cor-header/components';
import { isExternalUrl } from 'utils/is-external-url';
import {
  buildDesktopLabel,
  buildMobileLabel,
  handleOpenMenuBodyStyling,
} from 'components/layout/cor-header/utilities/handle-menus';

import './utility-nav-menu-item.scss';

export const UtilityNavMenuItem: FC<IMenuItemProps> = ({ ...props }) => {
  const {
    url,
    hintText,
    text,
    counter,
    iconLabel,
    image,
    menuPanelItems,
    notifications,
    menuPanelTitle,
    onClickHandler,
    onLogOut,
    isLast,
    isDisabled,
    isHiddenInMobileStickyHeader,
    closeHamburgerMenuHandler,
  } = props;
  const [panelIsOpen, setPanelIsOpen] = useState<boolean>(false);
  const { isMobile } = useBreakpoint();

  const menuPanelRef = useRef<CSSTransition>(null);
  const menuPanelDivRef = useRef<HTMLDivElement>(null);
  const ref: RefObject<HTMLButtonElement> = useRef(null);
  const { hideGlobalModal, modal } = useGlobalModalState();
  const isTsrUser = useSelector(selectIsTsrUser);
  const paymentsLink = Routes.AccountPayments || Routes.CanadaFrenchPayments;
  const menuFilteredPayments = menuPanelItems?.filter(({ url }) => !url?.includes(paymentsLink));
  const hasSubmenu = !!menuPanelItems && !!menuPanelItems.length;
  const isNotificationIcon = url === Routes.NewsAndNotificationsPage;

  useEffect(() => {
    handleOpenMenuBodyStyling({ overflow: panelIsOpen ? 'hidden' : 'auto' });
  }, [panelIsOpen]);

  const getNotificationUrl = (url) => url;

  const showNotificationListModal = useNotificationsListModal({
    notifications: notifications,
    notificationItemUrl: getNotificationUrl,
    excludeFromOutsideClickContentRef: ref,
    onClose: () => {
      hideGlobalModal();
      isMobile && handleOpenMenuBodyStyling({ overflow: 'auto' });
    },
  });

  const handleNotificationsPanel = () => {
    if (modal.isOpen) {
      hideGlobalModal();
      return;
    }
    showNotificationListModal();
    isMobile && handleOpenMenuBodyStyling({ overflow: 'hidden' });
  };

  const handleButtonClick = () => {
    // if mobile, buttons
    if (isMobile && !isNotificationIcon) {
      setPanelIsOpen(true);
      return;
    }
    if (isNotificationIcon) {
      handleNotificationsPanel();
      return;
    }
    if (onClickHandler) {
      return onClickHandler;
    }
    return;
  };

  const RenderLinkContent = ({ image, hintText, counter, isDisabled, iconLabel }) => {
    return (
      <>
        {hintText && !isDisabled && <span className="hint-text">{hintText}</span>}
        <HeaderIcon
          iconContentKey="image"
          data={image}
          counter={counter}
          customClass={isNotificationIcon ? 'notification-counter' : ''}
        />
        {iconLabel && !iconLabel.includes('undefined') && (
          <span
            data-testid={'utility-menu-item__label'}
            className={'utility-menu-item__label'}
            data-mobile-label={buildMobileLabel(iconLabel)}
            data-desktop-label={buildDesktopLabel(iconLabel)}
            aria-label={iconLabel}
          />
        )}
      </>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const MemoizedRenderLinkContent = useMemo(() => RenderLinkContent, []);

  const tabIndex = isDisabled ? -1 : 0;
  return (
    <li
      className={classNames('utility-menu-item', {
        'is-icon': !!image,
        'is-last-item': isLast,
        'is-disabled': isDisabled,
        'is-cart': url === Routes.ShoppingCartPage,
        'is-notifications': url === Routes.NewsAndNotificationsPage,
        'is-hidden-for-mobile-sticky': isHiddenInMobileStickyHeader,
      })}
    >
      {hasSubmenu && (
        <button
          ref={ref}
          data-testid={'utility-menu-item'}
          onClick={handleButtonClick}
          aria-label={text || ''}
          disabled={isDisabled}
          tabIndex={tabIndex}
        >
          {image ? (
            <MemoizedRenderLinkContent
              image={image}
              counter={counter}
              isDisabled={isDisabled}
              iconLabel={iconLabel}
              hintText={hintText}
            />
          ) : (
            <span>{text}</span>
          )}
        </button>
      )}
      {!hasSubmenu && (
        <CorContentstackLink
          className={isDisabled ? 'is-disabled' : ''}
          data-testid={'utility-menu-item'}
          data={{
            href: url,
            title: text || '',
          }}
          aria-label={text || ''}
          tabIndex={tabIndex}
          opensInNewTab={url ? isExternalUrl(url) : false}
        >
          {image ? (
            <MemoizedRenderLinkContent
              image={image}
              counter={counter}
              isDisabled={isDisabled}
              iconLabel={iconLabel}
              hintText={hintText}
            />
          ) : (
            <span>{text}</span>
          )}
        </CorContentstackLink>
      )}

      {hasSubmenu && (
        <CSSTransition
          ref={menuPanelRef}
          in={panelIsOpen}
          unmountOnExit
          timeout={0}
          classNames="utility-menu-panel-container"
        >
          <div ref={menuPanelDivRef} className="utility-menu-panel-container">
            <UtilityNavSubmenu
              onClickHandler={() => setPanelIsOpen(false)}
              menuPanelItems={isTsrUser ? menuFilteredPayments : menuPanelItems}
              menuPanelTitle={menuPanelTitle}
              onLogOut={onLogOut}
              closeHamburgerMenuHandler={closeHamburgerMenuHandler}
            />
          </div>
        </CSSTransition>
      )}
    </li>
  );
};
