import { lazy } from 'react';
import { AccessDefinitions } from 'constants/access-definitions.enum';
import { contentstackConfig } from 'constants/contentstack';
import { Layouts } from 'constants/layouts.enum';
import { Permissions } from 'constants/permissions';
import { Routes } from 'constants/routes.enum';
import { IAppRoute } from './routes';

const LoginPage = lazy(() => import('pages/login'));
const SelectLocationPage = lazy(() => import('pages/select-location'));
const HomePage = lazy(() => import('pages/home'));
const CustomerRegistrationPage = lazy(() => import('pages/customer-registration'));
const AdditionalUserRegistrationPage = lazy(() => import('pages/additional-user-registration'));
const ProductListPage = lazy(() => import('pages/product-list'));
const ProductSearchPage = lazy(() => import('pages/product-search'));
const CustomerRegistrationSuccessPage = lazy(() => import('pages/customer-registration-success'));
const ResetPasswordVerificationPage = lazy(() => import('pages/reset-password-verification'));
const ResetPasswordPage = lazy(() => import('pages/reset-password'));
const UpdatePasswordPage = lazy(() => import('pages/update-password'));
const EmailVerificationPage = lazy(() => import('pages/resend-verification-email'));
const ShoppingCartPage = lazy(() => import('pages/shopping-cart'));
const CheckoutPage = lazy(() => import('pages/checkout'));
const OrderConfirmationPage = lazy(() => import('pages/order-confirmation'));
const DashboardPage = lazy(() => import('pages/dashboard'));
const InternalUserQuotesPage = lazy(() => import('pages/internal-user-quotes'));
const OrderDetailsPage = lazy(() => import('pages/order-details'));
const AccessDeniedPage = lazy(() => import('pages/access-denied'));
const DoNotSellMyInfoPage = lazy(() => import('pages/do-not-sell-my-info'));
const ProfilePage = lazy(() => import('pages/account'));
const PaymentSummary = lazy(() => import('pages/payment-summary'));
const ProductDetailsPage = lazy(() => import('pages/product-details'));
const ShoppingListDetailsPage = lazy(() => import('pages/shopping-list-details'));
const ArticlePage = lazy(() => import('pages/article-page'));
const PromotionalPage = lazy(() => import('pages/promotional-page'));
const NewsAndNotificationsPage = lazy(() => import('pages/news-and-notifications'));
const HelpAndResourcesPage = lazy(() => import('pages/help-and-resources-page'));
const AdditionalResourcesPage = lazy(() => import('pages/additional-resources-page'));
const Corporate = lazy(() => import('corporate-components/cor-pages/corporate'));
const LivePreviewPage = lazy(() => import('corporate-components/cor-pages/corporate/pages/live-preview'));
const SearchResultsLazyPage = lazy(
  () => import('corporate-components/cor-pages/corporate/pages/search-results/search-results.page')
);
const Error404Page = lazy(() => import('pages/error-404'));
const PaymentDetails = lazy(() => import('pages/payment-details'));

export const routesConfig: IAppRoute[] = [
  {
    path: `/:country?/:lang?${Routes.LoginPage}`,
    exact: true,
    isRestricted: true,
    component: LoginPage,
    layout: Layouts.Auth,
    contentstackParams: contentstackConfig.login,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: Routes.SelectLocationPage,
    exact: true,
    isRestricted: true,
    component: SelectLocationPage,
    layout: Layouts.Auth,
    contentstackParams: contentstackConfig.selectLocation,
  },
  {
    path: `/:country?/:lang?${Routes.CanadaFrenchRegistrationPage}`,
    exact: true,
    isRestricted: true,
    component: CustomerRegistrationPage,
    layout: Layouts.Auth,
    contentstackParams: contentstackConfig.registration,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CustomerRegistrationPage}`,
    exact: true,
    isRestricted: true,
    component: CustomerRegistrationPage,
    layout: Layouts.Auth,
    contentstackParams: contentstackConfig.registration,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CustomerRegistrationSuccessPage}/:token?`,
    exact: true,
    isRestricted: true,
    component: CustomerRegistrationSuccessPage,
    layout: Layouts.Auth,
    contentstackParams: contentstackConfig.registrationSuccess,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `${Routes.AdditionalUserRegistrationPage}/:token`,
    exact: true,
    component: AdditionalUserRegistrationPage,
    layout: Layouts.Auth,
    unauthorizedLayout: Layouts.Auth,
    contentstackParams: contentstackConfig.addUserRegistration,
    unauthorizedContentstackParams: contentstackConfig.addUserRegistration,
  },
  {
    path: `/:country?/:lang?${Routes.CanadaFrenchShopAccount}`,
    exact: false,
    isPrivate: true,
    component: ProfilePage,
    layout: Layouts.AccountLayout,
    contentstackParams: contentstackConfig.account,
    hasDynamicContentstackPageUrl: true,
    additionalContentTypeToFetch: [
      {
        id: 'modals',
        pathToContent: 'modals[0]',
        include: contentstackConfig.account_modals.include,
      },
      {
        id: 'account_payment_history',
        pathToContent: 'tabs[6].payment.content[0].payment_history[0]',
        include: contentstackConfig.paymentHistory.include,
      },
    ],
  },
  {
    path: `/:country?/:lang?${Routes.ProfilePage}`,
    exact: false,
    isPrivate: true,
    component: ProfilePage,
    layout: Layouts.AccountLayout,
    contentstackParams: contentstackConfig.account,
    hasDynamicContentstackPageUrl: true,
    additionalContentTypeToFetch: [
      {
        id: 'modals',
        pathToContent: 'modals[0]',
        include: contentstackConfig.account_modals.include,
      },
      {
        id: 'account_payment_history',
        pathToContent: 'tabs[6].payment.content[0].payment_history[0]',
        include: contentstackConfig.paymentHistory.include,
      },
    ],
  },
  {
    path: `/:country?/:lang?${Routes.CanadaFrenchPaymentSummary}`,
    exact: false,
    isPrivate: true,
    component: PaymentSummary,
    layout: Layouts.AccountLayout,
    contentstackParams: contentstackConfig.account,
    hasDynamicContentstackPageUrl: true,
    additionalContentTypeToFetch: [
      {
        id: 'modals',
        pathToContent: 'modals[0]',
        include: contentstackConfig.account_modals.include,
      },
    ],
  },
  {
    path: `/:country?/:lang?${Routes.PaymentSummary}`,
    exact: false,
    isPrivate: true,
    component: PaymentSummary,
    layout: Layouts.AccountLayout,
    contentstackParams: contentstackConfig.account,
    hasDynamicContentstackPageUrl: true,
    additionalContentTypeToFetch: [
      {
        id: 'modals',
        pathToContent: 'modals[0]',
        include: contentstackConfig.account_modals.include,
      },
    ],
  },
  {
    path: `/:country?/:lang?${Routes.CanadaFrenchPaymentDetails}/:ctId`,
    exact: false,
    isPrivate: true,
    component: PaymentDetails,
    layout: Layouts.AccountLayout,
    contentstackParams: contentstackConfig.paymentDetails,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.PaymentDetails}/:ctId`,
    exact: false,
    isPrivate: true,
    component: PaymentDetails,
    layout: Layouts.AccountLayout,
    contentstackParams: contentstackConfig.paymentDetails,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `${Routes.ProductListPage}/:l1Category?/:l2Category?/:l3Category?`,
    isPrivate: true,
    component: ProductListPage,
    layout: Layouts.Default,
    contentstackParams: contentstackConfig.plp,
  },
  {
    path: `${Routes.ProductSearchPage}`,
    isPrivate: true,
    component: ProductSearchPage,
    layout: Layouts.Default,
    contentstackParams: contentstackConfig.plp,
  },
  {
    path: Routes.ShoppingCartPage,
    isPrivate: true,
    exact: true,
    component: ShoppingCartPage,
    layout: Layouts.Default,
    contentstackParams: contentstackConfig.shoppingCart,
    requiredPermissions: Permissions[AccessDefinitions.SHOPPING_CART_PAGE_ACCESS],
  },
  {
    path: Routes.CheckoutPage,
    isPrivate: true,
    exact: true,
    component: CheckoutPage,
    layout: Layouts.Checkout,
    contentstackParams: contentstackConfig.checkout,
    requiredPermissions: Permissions[AccessDefinitions.SHOPPING_CART_PAGE_ACCESS],
  },
  {
    path: Routes.OrderConfirmationPage,
    exact: true,
    isPrivate: true,
    component: OrderConfirmationPage,
    layout: Layouts.Default,
    contentstackParams: contentstackConfig.orderConfirmation,
  },
  {
    path: `/:country?/:lang?${Routes.ResetPasswordVerificationPage}`,
    exact: true,
    component: ResetPasswordVerificationPage,
    layout: Layouts.Auth,
    unauthorizedLayout: Layouts.Auth,
    contentstackParams: contentstackConfig.resetPasswordVerification,
    unauthorizedContentstackParams: contentstackConfig.resetPasswordVerification,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.ResetPasswordPage}/:token`,
    exact: true,
    component: ResetPasswordPage,
    layout: Layouts.Auth,
    unauthorizedLayout: Layouts.Auth,
    contentstackParams: contentstackConfig.resetPassword,
    unauthorizedContentstackParams: contentstackConfig.resetPassword,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.UpdatePasswordPage}`,
    exact: true,
    component: UpdatePasswordPage,
    layout: Layouts.Auth,
    unauthorizedLayout: Layouts.Auth,
    contentstackParams: contentstackConfig.updatePassword,
    unauthorizedContentstackParams: contentstackConfig.updatePassword,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.EmailVerificationPage}`,
    exact: true,
    component: EmailVerificationPage,
    layout: Layouts.Auth,
    unauthorizedLayout: Layouts.Auth,
    contentstackParams: contentstackConfig.emailVerification,
    unauthorizedContentstackParams: contentstackConfig.emailVerification,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: Routes.ShopHomePage,
    exact: true,
    isPrivate: true,
    component: HomePage,
    layout: Layouts.Default,
    contentstackParams: contentstackConfig.home,
  },
  {
    path: `${Routes.OrderDetails}/:ctId`,
    exact: true,
    isPrivate: true,
    component: OrderDetailsPage,
    layout: Layouts.Default,
    contentstackParams: contentstackConfig.orderDetails,
  },
  {
    path: Routes.DashboardPage,
    exact: true,
    isPrivate: true,
    isInternal: true,
    component: DashboardPage,
    layout: Layouts.InternalUser,
    contentstackParams: contentstackConfig.dashboard,
  },
  {
    path: Routes.DashboardQuotesPage,
    exact: true,
    isPrivate: true,
    isInternal: true,
    component: InternalUserQuotesPage,
    layout: Layouts.InternalUser,
    contentstackParams: contentstackConfig.dashboard,
    requiredPermissions: Permissions[AccessDefinitions.DASHBOARD_QUOTES_PAGE_ACCESS],
  },
  {
    path: `/:country?/:lang?${Routes.FrequentlyAskedQuestionsPage}`,
    exact: true,
    isPrivate: true,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporatePage,
    unauthorizedContentstackParams: contentstackConfig.corporatePage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.GuestFrequentlyAskedQuestionsPage}`,
    exact: true,
    component: Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporatePage,
    unauthorizedContentstackParams: contentstackConfig.corporatePage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: Routes.AccessDeniedPage,
    exact: true,
    component: AccessDeniedPage,
    layout: Layouts.Basic,
    unauthorizedLayout: Layouts.Basic,
    contentstackParams: contentstackConfig.accessDenied,
    unauthorizedContentstackParams: contentstackConfig.accessDenied,
  },
  {
    path: `/:country?/:lang?${Routes.DoNotSellMyInfo}`,
    exact: true,
    component: DoNotSellMyInfoPage,
    layout: Layouts.Default,
    unauthorizedLayout: Layouts.Auth,
    contentstackParams: contentstackConfig.doNotSellMyInfo,
    unauthorizedContentstackParams: contentstackConfig.unauthorizedDoNotSellMyInfo,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `${Routes.ProductDetailsPage}/:productId`,
    isPrivate: true,
    component: ProductDetailsPage,
    layout: Layouts.Default,
    contentstackParams: contentstackConfig.productDetails,
  },
  {
    path: `${Routes.ShoppingListDetailsPage}/:shoppingListId`,
    isPrivate: true,
    component: ShoppingListDetailsPage,
    layout: Layouts.Default,
    contentstackParams: contentstackConfig.shoppingListDetails,
  },
  {
    path: `${Routes.ArticlePage}/:articleId`,
    isPrivate: true,
    component: ArticlePage,
    layout: Layouts.Default,
    contentstackParams: contentstackConfig.article,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `${Routes.PromotionalPage}/:promo`,
    isPrivate: true,
    component: PromotionalPage,
    layout: Layouts.Default,
    contentstackParams: contentstackConfig.promotionalPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: Routes.NewsAndNotificationsPage,
    isPrivate: true,
    component: NewsAndNotificationsPage,
    layout: Layouts.Default,
    contentstackParams: contentstackConfig.newsAndNotificationsPage,
  },
  {
    path: `${Routes.HelpAndResourcesPage}/:pageId`,
    isPrivate: true,
    component: AdditionalResourcesPage,
    layout: Layouts.Default,
    contentstackParams: contentstackConfig.additionalResourcesPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: Routes.HelpAndResourcesPage,
    isPrivate: true,
    component: HelpAndResourcesPage,
    layout: Layouts.Default,
    contentstackParams: contentstackConfig.helpAndResourcesPage,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateRecipesPage}/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateRecipesPage,
    unauthorizedContentstackParams: contentstackConfig.corporateRecipesPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateLatamRecipesPage}/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateRecipesPage,
    unauthorizedContentstackParams: contentstackConfig.corporateRecipesPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateDachRecipesPage}/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateRecipesPage,
    unauthorizedContentstackParams: contentstackConfig.corporateRecipesPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateSpainRecipesPage}/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateRecipesPage,
    unauthorizedContentstackParams: contentstackConfig.corporateRecipesPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporatePlRecipesPage}/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateRecipesPage,
    unauthorizedContentstackParams: contentstackConfig.corporateRecipesPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporatePtRecipesPage}/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateRecipesPage,
    unauthorizedContentstackParams: contentstackConfig.corporateRecipesPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateHuRecipesPage}/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateRecipesPage,
    unauthorizedContentstackParams: contentstackConfig.corporateRecipesPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateBeFrenchRecipesPage}/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateRecipesPage,
    unauthorizedContentstackParams: contentstackConfig.corporateRecipesPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateCaFrRecipesPage}/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateRecipesPage,
    unauthorizedContentstackParams: contentstackConfig.corporateRecipesPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.ShopHomePage}/*`,
    isPrivate: false,
    component: Error404Page,
    layout: Layouts.Default,
    unauthorizedLayout: Layouts.Auth,
    contentstackParams: contentstackConfig.error404,
    unauthorizedContentstackParams: contentstackConfig.unauthorizedError404,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateFrRecipesPage}/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateRecipesPage,
    unauthorizedContentstackParams: contentstackConfig.corporateRecipesPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateNlRecipesPage}/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateRecipesPage,
    unauthorizedContentstackParams: contentstackConfig.corporateRecipesPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateBeDutchRecipesPage}/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateRecipesPage,
    unauthorizedContentstackParams: contentstackConfig.corporateRecipesPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateNewsPage}/:year/:month/:day/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateNewsPage,
    unauthorizedContentstackParams: contentstackConfig.corporateNewsPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateLatamNewsPage}/:year/:month/:day/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateNewsPage,
    unauthorizedContentstackParams: contentstackConfig.corporateNewsPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateDachNewsPage}/:year/:month/:day/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateNewsPage,
    unauthorizedContentstackParams: contentstackConfig.corporateNewsPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateSpainNewsPage}/:year/:month/:day/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateNewsPage,
    unauthorizedContentstackParams: contentstackConfig.corporateNewsPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporatePlNewsPage}/:year/:month/:day/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateNewsPage,
    unauthorizedContentstackParams: contentstackConfig.corporateNewsPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporatePtNewsPage}/:year/:month/:day/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateNewsPage,
    unauthorizedContentstackParams: contentstackConfig.corporateNewsPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateHuNewsPage}/:year/:month/:day/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateNewsPage,
    unauthorizedContentstackParams: contentstackConfig.corporateNewsPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateBeFrenchNewsPage}/:year/:month/:day/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateNewsPage,
    unauthorizedContentstackParams: contentstackConfig.corporateNewsPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateCaFrNewsPage}/:year/:month/:day/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateNewsPage,
    unauthorizedContentstackParams: contentstackConfig.corporateNewsPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateFrNewsPage}/:year/:month/:day/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateNewsPage,
    unauthorizedContentstackParams: contentstackConfig.corporateNewsPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateNlNewsPage}/:year/:month/:day/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateNewsPage,
    unauthorizedContentstackParams: contentstackConfig.corporateNewsPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateBeDutchNewsPage}/:year/:month/:day/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateNewsPage,
    unauthorizedContentstackParams: contentstackConfig.corporateNewsPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateInsightPage}/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateInsightsPage,
    unauthorizedContentstackParams: contentstackConfig.corporateInsightsPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateLatamInsightPage}/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateInsightsPage,
    unauthorizedContentstackParams: contentstackConfig.corporateInsightsPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateDachInsightPage}/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateInsightsPage,
    unauthorizedContentstackParams: contentstackConfig.corporateInsightsPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateSpainInsightPage}/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateInsightsPage,
    unauthorizedContentstackParams: contentstackConfig.corporateInsightsPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporatePlInsightPage}/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateInsightsPage,
    unauthorizedContentstackParams: contentstackConfig.corporateInsightsPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporatePtInsightPage}/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateInsightsPage,
    unauthorizedContentstackParams: contentstackConfig.corporateInsightsPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateHuInsightPage}/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateInsightsPage,
    unauthorizedContentstackParams: contentstackConfig.corporateInsightsPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateBeFrenchInsightPage}/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateInsightsPage,
    unauthorizedContentstackParams: contentstackConfig.corporateInsightsPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateCaFrInsightPage}/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateInsightsPage,
    unauthorizedContentstackParams: contentstackConfig.corporateInsightsPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateFrInsightPage}/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateInsightsPage,
    unauthorizedContentstackParams: contentstackConfig.corporateInsightsPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateNlInsightPage}/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateInsightsPage,
    unauthorizedContentstackParams: contentstackConfig.corporateInsightsPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.CorporateBeDutchInsightPage}/:pageId`,
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporateInsightsPage,
    unauthorizedContentstackParams: contentstackConfig.corporateInsightsPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `${Routes.LivePreviewPage}/:country?/:lang?/:entry`,
    isPrivate: false,
    component: LivePreviewPage,
    layout: Layouts.LivePreview,
    unauthorizedLayout: Layouts.LivePreview,
    contentstackParams: contentstackConfig.livePreviewPage,
    unauthorizedContentstackParams: contentstackConfig.livePreviewPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `${Routes.LivePreviewPage}/shop/:entry`,
    isPrivate: false,
    component: LivePreviewPage,
    layout: Layouts.LivePreview,
    unauthorizedLayout: Layouts.LivePreview,
    contentstackParams: contentstackConfig.livePreviewPage,
    unauthorizedContentstackParams: contentstackConfig.livePreviewPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `${Routes.LivePreviewComponent}/:contentType/:entry`,
    isPrivate: false,
    component: LivePreviewPage,
    layout: Layouts.LivePreview,
    unauthorizedLayout: Layouts.LivePreview,
    contentstackParams: contentstackConfig.livePreviewPage,
    unauthorizedContentstackParams: contentstackConfig.livePreviewPage,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: `/:country?/:lang?${Routes.SearchResults}`,
    isPrivate: false,
    component: SearchResultsLazyPage,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.searchResults,
    unauthorizedContentstackParams: contentstackConfig.searchResults,
    hasDynamicContentstackPageUrl: true,
  },
  {
    path: '/:country?/:lang?/*',
    isPrivate: false,
    component: Corporate,
    layout: Layouts.Corporate,
    unauthorizedLayout: Layouts.Corporate,
    contentstackParams: contentstackConfig.corporatePage,
    unauthorizedContentstackParams: contentstackConfig.corporatePage,
    hasDynamicContentstackPageUrl: true,
  },
];
