import { FC, HTMLAttributes } from 'react';
import { get } from 'lodash-es';
import { useContentstackContent } from 'hooks/use-contentstack-content';
import { getLocalizedShopUrl } from 'utils/get-localized-shop-url';
import { Routes } from 'constants/routes.enum';

interface IContentstackLinkComponentProps extends HTMLAttributes<HTMLAnchorElement> {
  contentKey: string;
  target?: string;
}

export interface IContentstackLink {
  href?: string;
  title?: string;
}

export const ContentstackLink: FC<IContentstackLinkComponentProps> = (props) => {
  const { contentKey, target, ...elementProps } = props;
  const content = useContentstackContent();
  const link: IContentstackLink = get(content, contentKey, {});
  const linkHref = link?.href;

  return (
    <a {...elementProps} href={getLocalizedShopUrl(linkHref as Routes)} target={target}>
      {link.title}
    </a>
  );
};
