import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CorRegionSelector } from 'corporate-components';
import { UtilityNavMenuItem } from 'components/layout/cor-header/components';
import { useMainNavState } from 'components/layout/cor-header/context/headerContext';
import { IMainMenuLink, IMenuItemProps, IUtilityNavUserInfo } from 'components/layout/cor-header/cor-header.interface';
import { selectCustomerLocations } from 'store/customer-login/selectors';
import { Routes } from 'constants/routes.enum';
import { logOutClickHandler } from 'utils/link-utils';
import { selectIsAuthorised, selectIsImpersonateModeOn, selectIsInvoiceOnlyCustomer } from 'store/auth/selectors';
import iconSearch from 'assets/images/icons/icon-search-mobile-header.svg';
import { useLocalStorage } from 'hooks/use-local-storage';
import { isInternationalSite } from 'utils/get-locale-params';
import { CorButton } from 'components/cor-button';
import { selectIsHeaderCollapsed } from 'store/ui/selectors';

export interface IUtilityMenuMobileProps {
  className?: string;
  menuItems?: IMenuItemProps[];
  shopButtonLabel?: string;
  menuTitle?: string;
  onLogout?: (e: React.SyntheticEvent<HTMLAnchorElement>) => void;
  userInfo?: IUtilityNavUserInfo;
  closeHamburgerMenuHandler?: (url) => void;
  menuDropdownOptions?: IMainMenuLink[];
}

export const UtilityNavMobile: FC<IUtilityMenuMobileProps> = ({
  menuItems,
  userInfo,
  shopButtonLabel,
  menuDropdownOptions,
  closeHamburgerMenuHandler,
}) => {
  const customerLocations = useSelector(selectCustomerLocations);
  const isImpersonateModeOn = useSelector(selectIsImpersonateModeOn);
  const isStickyHeaderCollapsed = useSelector(selectIsHeaderCollapsed);
  const isInvoiceOnlyUser = useSelector(selectIsInvoiceOnlyCustomer);
  const isUserAuthorized = useSelector(selectIsAuthorised);
  const [locale] = useLocalStorage('locale', null);
  const dispatch = useDispatch();
  const { isLoggedInUser, setIsSearchMobileOpen } = useMainNavState();
  const history = useHistory();
  const handleLogOutClick = React.useCallback(
    (e) => {
      if (!isImpersonateModeOn) {
        logOutClickHandler(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, isImpersonateModeOn]
  );

  const { userName, menuOptions, text } = userInfo || {};

  const showDropdown = isLoggedInUser && userName && menuOptions && text;

  const userMenuData: IMenuItemProps = {
    url: isLoggedInUser ? '#' : Routes.LoginPage,
    hintText: userInfo?.text,
    iconLabel: showDropdown ? userInfo?.userName : '',
    image: userInfo?.image,
    menuPanelItems: menuDropdownOptions?.map((loc) => {
      const children = loc.url === Routes.AccountLocationsPage ? customerLocations : [];
      return { ...loc, children };
    }),
    isHiddenInMobileStickyHeader: true,
    menuPanelTitle: userInfo?.text,
  };

  // filter menu items for displaying in the mobile utility menu...
  const filteredMenuItems = menuItems?.filter((menuItem) => menuItem.showInNav);

  const getNavItemUrl = (item) => {
    const isLoginURL = item.url === Routes.LoginPage;
    const isRegistrationURL = item.url === Routes.CustomerRegistrationPage;
    const isInvoiceAuthorizedUSUser =
      (isLoginURL || isRegistrationURL) && isInvoiceOnlyUser && isUserAuthorized && !isInternationalSite(locale);

    if (isInvoiceAuthorizedUSUser) {
      return Routes.AccountPayments;
    }
    return item.url;
  };

  return (
    <ul className={'utility-menu__items'}>
      {isLoggedInUser && isStickyHeaderCollapsed && (
        <CorButton className="utility-menu__shop-btn" color="primary" onClick={() => history.push(Routes.ShopHomePage)}>
          {shopButtonLabel}
        </CorButton>
      )}
      {isLoggedInUser && (
        <UtilityNavMenuItem
          {...userMenuData}
          closeHamburgerMenuHandler={closeHamburgerMenuHandler}
          onLogOut={handleLogOutClick}
          isMobile
        />
      )}
      {isLoggedInUser && (
        <li className="utility-menu__search-button">
          <button onClick={() => setIsSearchMobileOpen(true)} className="button-reset">
            <img src={iconSearch} alt="search button icon" />
          </button>
        </li>
      )}
      {filteredMenuItems &&
        filteredMenuItems.map((item: IMenuItemProps, index) => (
          <UtilityNavMenuItem
            key={`${item.url}-${index}`}
            {...item}
            url={getNavItemUrl(item)}
            isLast={index === filteredMenuItems.length - 1}
            closeHamburgerMenuHandler={closeHamburgerMenuHandler}
          />
        ))}
      {!isLoggedInUser && <UtilityNavMenuItem {...userMenuData} />}
      {isInternationalSite(locale) && <CorRegionSelector direction="down" />}
    </ul>
  );
};
