import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from 'constants/routes.enum';
import { allProductsMapper } from 'components/layout/cor-header/mappers';
import { useMainNavState } from 'components/layout/cor-header/context/headerContext';
import { LoadingIndicator } from 'components/loading-indicator';
import { IAllProductsCategory } from 'components/layout/cor-header/cor-header.interface';
import { MainNavDrillDownSubmenu } from 'components/layout/cor-header/components';

import './main-nav-drill-down-menu.scss';

export interface IMainNavSubmenu {
  label: string;
  items: IAllProductsCategory[];
  parent: string;
  closeHamburgerMenuHandler?: (url: string) => void;
}

const rootItem: string = 'root';

export const MainNavDrillDownMenu: FC<IMainNavSubmenu> = ({ parent, label, items, closeHamburgerMenuHandler }) => {
  const history = useHistory();
  const { openedMenu } = useMainNavState();
  const resizeContainer = useRef<HTMLDivElement>(null);
  const [activePanels, setActivePanels] = useState<string[]>([rootItem]);
  const [height, setHeight] = useState('auto');
  const menuIsOpen: boolean = !!openedMenu[parent];

  useEffect(() => {
    if (menuIsOpen) {
      setActivePanels([rootItem]);
    }
  }, [menuIsOpen, parent]);

  const setActiveItem = (event, item, path, isLink): void => {
    event?.preventDefault();
    if (isLink) {
      closeHamburgerMenuHandler && isLink && closeHamburgerMenuHandler(`${Routes.ProductListPage}/${path}`);
      history.push(`${Routes.ProductListPage}/${path}`);
      return;
    }
    const hasItem = activePanels.includes(item);
    const items = hasItem ? activePanels.filter((panel) => panel !== item) : [...activePanels, item];
    setActivePanels(items);
  };

  const allPanels = items.length ? allProductsMapper(items, null, rootItem, []) : [];

  const panelWithRoot = allPanels.map((panel) => {
    return !panel.back
      ? {
          ...panel,
          back: {
            breadcrumbs: [
              {
                key: rootItem,
                name: label,
              },
            ],
            categoryKey: label,
            categoryName: label,
            isSelected: false,
            level: 'L0',
            id: label,
          },
        }
      : panel;
  });

  return (
    <div className="main-subnav">
      {!items.length && (
        <div className="main-subnav__loading">
          <LoadingIndicator smallSpinner={true} />
        </div>
      )}
      {items.length > 0 && (
        <div ref={resizeContainer} className="subnav-window" style={{ height: height }}>
          {panelWithRoot.map((panel, index) => {
            return (
              <MainNavDrillDownSubmenu
                key={index}
                activePanels={activePanels}
                data={panel}
                clickPanel={setActiveItem}
                setHeight={(h) => setHeight(h)}
                isOpen={menuIsOpen}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
