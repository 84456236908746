import { useCallback } from 'react';

export const useBlueSnapSecuredPaymentCollector = (
  token: string,
  onSuccessCallBack: () => void,
  onFailCallBack: () => void
) => {
  const securedPaymentCollectorSetup = useCallback(() => {
    if (token) {
      bluesnap.securedPaymentCollectorSetup(token, function (sdkResponse) {
        if (sdkResponse.code === '1') {
          onSuccessCallBack();
        } else {
          onFailCallBack();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const securedPaymentCollectorSubmitData = useCallback(() => {
    const data = {
      amount: 0,
      currency: 'USD',
    };
    bluesnap.securedPaymentCollectorSubmitData(data);
  }, []);

  return {
    securedPaymentCollectorSetup,
    securedPaymentCollectorSubmitData,
  };
};
