import { FC, ReactNode, useRef } from 'react';
import Slider from 'react-slick';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { DiscontinuedWithReplacementItem } from 'features/discontinued-items-with-replacements';
import { IOriginalToReplacement } from 'components/modals/all-items-added-to-cart-result-message-modal';

import './discontinued-with-replacement-slider.scss';

export interface IDiscontinuedWithReplacementSliderProps {
  originalToReplacementMap: IOriginalToReplacement[] | undefined;
  createRequestQuoteClickHandler: (sku: string) => () => void;
  activeSlide?: number;
  onSlideChange?: (currentSlide: number) => void;
}

export const DiscontinuedWithReplacementSlider: FC<IDiscontinuedWithReplacementSliderProps> = ({
  originalToReplacementMap,
  createRequestQuoteClickHandler,
  activeSlide = 0,
  onSlideChange,
}) => {
  const contentstackPath = 'modals.0.discontinued_item_modal.0';
  const sliderRef = useRef<Slider>(null);
  const { isDesktop } = useBreakpoint();

  const sliderSettings = {
    ref: sliderRef,
    speed: 500,
    touchMove: false,
    arrows: false,
    dots: true,
    appendDots: (dots: ReactNode) => (
      <div>
        {isDesktop && (
          <button
            className="discontinued-with-replacement-slider__arrow discontinued-with-replacement-slider__prev button-reset"
            onClick={() => {
              sliderRef?.current?.slickPrev();
            }}
          />
        )}
        <ul>{dots}</ul>
        {isDesktop && (
          <button
            className="discontinued-with-replacement-slider__arrow discontinued-with-replacement-slider__next button-reset"
            onClick={() => {
              sliderRef?.current?.slickNext();
            }}
          />
        )}
      </div>
    ),
    dotsClass: 'discontinued-with-replacement-slider__dots slick-dots',
    responsive: [
      {
        breakpoint: 960,
        settings: {
          arrows: false,
          touchMove: true,
        },
      },
    ],
    afterChange: onSlideChange,
    initialSlide: activeSlide,
  };

  return (
    <Slider className="discontinued-with-replacement-slider" {...sliderSettings}>
      {originalToReplacementMap?.map(({ original, replacement }) => (
        <div className="discontinued-with-replacement-slider__slide" key={`${original.sku}-${replacement.sku}`}>
          <DiscontinuedWithReplacementItem
            contentstackPath={contentstackPath}
            original={original}
            replacement={replacement}
            createRequestQuoteClickHandler={createRequestQuoteClickHandler}
          />
        </div>
      ))}
    </Slider>
  );
};
