import { FC, ChangeEvent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { Routes } from 'constants/routes.enum';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { ProductImage } from 'components/product-image';
import { useContent } from 'hooks/use-content';
import { ContentstackText, ContentstackMessage } from 'components/contentstack';
import { CorButton } from 'components/cor-button';
import { getSkuInfo, requestQuote, resetSkuInfoDetails } from 'store/quotes/actions';
import { IRequestQuotePayload } from 'store/quotes/sagas/request-quote';
import { clearRequestQuoteSimilarItems, getRequestQuoteSimilarItems } from 'store/similar-items/actions';
import { IGetSimilarItemsPayload } from 'store/similar-items/sagas/get-similar-items';
import { QuantityControl } from 'components/quantity-control';
import { SkuQuantityLimitationAlert } from 'components/sku-quantity-limitation-alert';
import { SkuQuantityLimitationWarning } from 'components/sku-quantity-limitation-warning';
import { stripLeadingZeros } from 'utils/string-format';
import { LoadingIndicator } from 'components/loading-indicator';
import { IGetSkuInfoPayload } from 'store/quotes/sagas/get-sku-info';
import {
  selectRequestQuoteSimilarItemsData,
  selectRequestQuoteSimilarItemsLoading,
} from 'store/similar-items/selectors';
import { selectSkuInfoDetails, selectSkuInfoLoading } from 'store/quotes/selectors';
import { ProductStatusLabels } from 'components/product-status-labels';
import { CustomTooltip } from 'components/custom-tooltip';
import { SimilarItems } from 'features/similar-items';
import { ModalFooter } from 'components/modals/common/modal/components';
import iconInfo from 'assets/images/icons/icon-info.svg';
import iconInfoDisabled from 'assets/images/icons/icon-info-disabled.svg';
import { ProductNewArrivalBadge } from 'components/product-new-arrival-badge';

import './request-quote-modal.scss';

export interface IRequestQuoteModalProps extends IModalProps {
  requestQuoteSuccessCallBack?: () => void;
  requestQuoteFailCallBack?: () => void;
  skuKey?: string;
  onRequestSimilarItemQuote?: () => void;
  recipeDetailPageContentstackPath?: string;
}

export const RequestQuoteModal: FC<IRequestQuoteModalProps> = ({
  isOpen,
  onClose,
  skuKey,
  requestQuoteSuccessCallBack,
  requestQuoteFailCallBack,
  onRequestSimilarItemQuote,
  recipeDetailPageContentstackPath,
}) => {
  const contentstackPath = 'modals.0.request_quote_modal.0';
  const dispatch = useDispatch();
  const quantityInputRef = useRef<HTMLInputElement>(null);
  const skuInfo = useSelector(selectSkuInfoDetails);
  const minimumOrderQuantity = skuInfo?.minimumOrderQuantity || 1;
  const hasMinimumQuantityLimitation = minimumOrderQuantity > 1;
  const hasMaximumQuantityLimitation = !!skuInfo?.maximumQuantity && skuInfo?.maximumQuantity > 0;
  const isShortSupplySku = skuInfo?.maximumQuantity === 0;
  const isSkuInfoLoading = useSelector(selectSkuInfoLoading);
  const requestQuoteSimilarItems = useSelector(selectRequestQuoteSimilarItemsData);
  const isRequestQuoteSimilarItemsLoading = useSelector(selectRequestQuoteSimilarItemsLoading);

  const [messageText, setMessageText] = useState('');
  const imageDesktopSize = { width: 296, height: 296 };
  const imageMobileSize = { width: 328, height: 328 };
  const [isRequestQuoteButtonDisabled, setRequestQuoteButtonDisabled] = useState(false);

  const { getContentByKey } = useContent();

  useEffect(() => {
    if (isOpen && skuKey) {
      dispatch(
        getSkuInfo.request<IGetSkuInfoPayload>({
          sku: skuKey,
        })
      );
    }

    return () => {
      setMessageText('');
      dispatch(resetSkuInfoDetails());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOpen, skuKey]);

  useEffect(() => {
    if (isOpen && skuInfo?.similarItems && !isEmpty(skuInfo?.similarItems)) {
      dispatch(
        getRequestQuoteSimilarItems.request<IGetSimilarItemsPayload>({
          skus: skuInfo?.similarItems.join(','),
        })
      );
    }

    return () => {
      dispatch(clearRequestQuoteSimilarItems());
    };
  }, [dispatch, isOpen, skuInfo]);

  const sendQuoteRequest = () => {
    if (skuInfo.sku) {
      dispatch(
        requestQuote.request<IRequestQuotePayload>({
          sku: skuInfo.sku,
          comment: messageText,
          quantity: Number(quantityInputRef.current?.value) || Number(minimumOrderQuantity) || 1,
          onSuccessCallback: requestQuoteSuccessCallBack,
          onFailCallback: requestQuoteFailCallBack,
        })
      );
    }
  };

  const onRequestQuoteClick = () => {
    setRequestQuoteButtonDisabled(true);
    setTimeout(() => setRequestQuoteButtonDisabled(false), 1000);

    sendQuoteRequest();
  };

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value;
    setMessageText(inputValue);
  };

  const skuClasses = classNames('request-quote-modal__content');
  const modalClasses = classNames('request-quote-modal', {
    'request-quote-modal--with-loading-indicator': isSkuInfoLoading || isRequestQuoteSimilarItemsLoading,
  });

  const getQuantityWarningMessage = () => {
    if (isShortSupplySku) return 'MSG091';
    if (hasMinimumQuantityLimitation && !hasMaximumQuantityLimitation) return 'MSG015';
    if (hasMaximumQuantityLimitation && hasMinimumQuantityLimitation) return 'MSG093';
    if (hasMaximumQuantityLimitation && !hasMinimumQuantityLimitation) return 'MSG094';
    return null;
  };
  const quantityWarningMessageId = getQuantityWarningMessage();

  return (
    <Modal
      size="large"
      isOpen={isOpen}
      parentClassName="request-quote-modal-wrapper"
      className={modalClasses}
      onClose={onClose}
      withBackdropClick
    >
      <div className={skuClasses}>
        {isSkuInfoLoading || isRequestQuoteSimilarItemsLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <div className="request-quote-modal__product-details grid-x">
              <div className="small-12 medium-4">
                <div className="request-quote-modal__image">
                  <Link
                    className="product-card__link"
                    to={`${Routes.ProductDetailsPage}/${skuInfo?.productKey}?selectedSku=${skuInfo?.sku}`}
                  >
                    <ProductImage
                      src={skuInfo.imageUrl}
                      altText={skuInfo.name}
                      desktopSize={imageDesktopSize}
                      mobileSize={imageMobileSize}
                    />
                    <div className="product-card__statuses-wrapper">
                      <ProductStatusLabels
                        isSpecialOrder
                        isPhasingOut={skuInfo.isPhasingOut}
                        contentstackPath={recipeDetailPageContentstackPath}
                      />
                    </div>
                  </Link>
                </div>
                {quantityWarningMessageId && (
                  <SkuQuantityLimitationWarning className="request-quote-modal__quantity-warning">
                    <ContentstackMessage type="notifications" messageId={quantityWarningMessageId} />
                  </SkuQuantityLimitationWarning>
                )}
              </div>
              <div className="small-12 medium-8 request-quote-modal__main">
                <div
                  className={classNames('request-quote-modal__main-content', {
                    'request-quote-modal__main-content--with-similar-items': !isEmpty(skuInfo?.similarItems),
                  })}
                >
                  <div className="request-quote-modal__product-info">
                    <p className="request-quote-modal__brand">{skuInfo?.brand}</p>
                    <p className="request-quote-modal__name">
                      <Link
                        className="product-card__link"
                        to={`${Routes.ProductDetailsPage}/${skuInfo?.productKey}?selectedSku=${skuInfo?.sku}`}
                      >
                        {skuInfo?.newArrival && (
                          <ProductNewArrivalBadge contentKey={`${contentstackPath}.new_arrival_badge`} />
                        )}
                        {skuInfo?.name}
                      </Link>
                    </p>
                    <p className="request-quote-modal__info-item">
                      <span className="request-quote-modal__label">
                        <ContentstackText contentKey={`${contentstackPath}.item_number_label`} />
                      </span>
                      <span className="request-quote-modal__info-value">
                        {skuInfo?.sku && stripLeadingZeros(skuInfo.sku)}
                      </span>
                    </p>
                    <p className="request-quote-modal__info-item">
                      <span className="request-quote-modal__label">
                        <ContentstackText contentKey={`${contentstackPath}.selling_unit_format_label`} />
                      </span>
                      <span className="request-quote-modal__info-value">{skuInfo?.packing}</span>
                    </p>
                  </div>
                  {!isShortSupplySku && (
                    <>
                      <div className="request-quote-modal__quantity-block">
                        <div className="request-quote-modal__quantity-label-area">
                          <span className="request-quote-modal__quantity-label">
                            <ContentstackText contentKey={`${contentstackPath}.quantity_label`} />
                          </span>
                          <QuantityControl
                            className="request-quote-modal__quantity-control"
                            quantity={minimumOrderQuantity}
                            minQuantity={minimumOrderQuantity}
                            maxQuantity={skuInfo?.maximumQuantity}
                            contentStackPath={contentstackPath}
                            inputRef={quantityInputRef}
                          />
                        </div>
                        {hasMinimumQuantityLimitation && (
                          <SkuQuantityLimitationAlert className="request-quote-modal__quantity-notification">
                            <ContentstackMessage
                              type="notifications"
                              messageId="MSG077"
                              interpolateParams={{
                                minimumOrderQuantity: minimumOrderQuantity,
                              }}
                            />
                          </SkuQuantityLimitationAlert>
                        )}
                        {hasMaximumQuantityLimitation && (
                          <SkuQuantityLimitationAlert className="request-quote-modal__quantity-notification">
                            <ContentstackMessage
                              type="notifications"
                              messageId="MSG092"
                              interpolateParams={{
                                maximumOrderQuantity: skuInfo?.maximumQuantity,
                              }}
                            />
                          </SkuQuantityLimitationAlert>
                        )}
                      </div>
                      <div className="request-quote-modal__message-block">
                        <p className="request-quote-modal__message-block-label">
                          <ContentstackText contentKey={`${contentstackPath}.message_label`} />
                        </p>
                        <textarea
                          className="request-quote-modal__message-block-input"
                          placeholder={getContentByKey<string>(`${contentstackPath}.message_placeholder`, '')}
                          value={messageText}
                          maxLength={300}
                          onChange={handleInputChange}
                          aria-label="Message (Optional)"
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {!isEmpty(requestQuoteSimilarItems) && (
              <div className="request-quote-modal__similar-items">
                <SimilarItems
                  similarItems={requestQuoteSimilarItems}
                  contentstackPath={`${contentstackPath}.similar_items`}
                  onRequestSimilarItemQuote={onRequestSimilarItemQuote}
                  recipeDetailPageContentstackPath={`${contentstackPath}.similar_items.alternative_items_line_item[0]`}
                />
              </div>
            )}

            <ModalFooter className="request-quote-modal__footer">
              <div className="request-quote-modal__special-order-footer">
                <CorButton
                  className="request-quote-modal__request-quote-button"
                  onClick={onRequestQuoteClick}
                  disabled={isRequestQuoteButtonDisabled || isShortSupplySku}
                >
                  <ContentstackText contentKey={`${contentstackPath}.request_button_label`} />
                </CorButton>
                <CustomTooltip
                  customIcon={isRequestQuoteButtonDisabled || isShortSupplySku ? iconInfoDisabled : iconInfo}
                  tooltipId={`requestQuoteTooltip-${skuKey}`}
                >
                  <ContentstackMessage type="tooltips" messageId="MSG107" />
                </CustomTooltip>
              </div>
            </ModalFooter>
          </>
        )}
      </div>
    </Modal>
  );
};
