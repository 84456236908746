import { FC, PropsWithChildren } from 'react';
import { IAnyObject } from 'types/generic-types';
import { everyIsNotEmpty } from 'utils/conditions';

interface IRenderIfEveryNonEmptyProps extends PropsWithChildren {
  data: IAnyObject;
  keys: string[];
}

export const RenderIfEveryNonEmpty: FC<IRenderIfEveryNonEmptyProps> = ({ data, keys, children }) =>
  everyIsNotEmpty(data, keys) ? <>{children}</> : null;
