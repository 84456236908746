import './cor-footer-legal.scss';

import React from 'react';
import { corContentstackImageLinkResolver } from 'corporate-utils/cor-contentstack-image-link-resolver';
import { CorContentstackLink } from 'corporate-components/cor-contentstack';
import { CorRegionSelector } from 'corporate-components/cor-region-selector';
import { ContentstackText } from 'components/contentstack';
import { useContent } from 'hooks/use-content';
import { IconWithHover } from 'components/icon-with-hover';
import { ICSUtilityNavLogo } from 'components/layout/cor-header/cor-header.interface';

const FOOTER_LEGAL_LINKS_KEY = 'footer_legal_links';
const SOCIAL_NETWORKS_KEY = 'social_links';

export interface ISocialNetwork {
  icon: ICSUtilityNavLogo;
}

const CorFooterLegal = () => {
  const { getContentByKey } = useContent();
  const footerLegalLinks = getContentByKey(FOOTER_LEGAL_LINKS_KEY, []);
  const socialNetworks: ISocialNetwork[] = getContentByKey(SOCIAL_NETWORKS_KEY, []);

  return (
    <div className="cor-footer__legal cor-footer-desktop__legal" data-testid="cor-footer-desktop__legal">
      <div className="grid-container">
        <p className="legal__text" data-testid="legal__text">
          <ContentstackText
            contentKey="copyright_block[0].text"
            interpolateParams={{ currentYear: new Date().getFullYear() }}
          />
        </p>
        {!!footerLegalLinks.length && (
          <div className="legal__links" data-testid="legal__links">
            {footerLegalLinks.map(({ opens_in_a_new_tab = false }, index) => (
              <CorContentstackLink
                key={index.toString()}
                contentKey={`${FOOTER_LEGAL_LINKS_KEY}[${index}].link_url`}
                className="links__link"
                opensInNewTab={opens_in_a_new_tab}
              />
            ))}
          </div>
        )}

        <CorRegionSelector direction="up" />

        {!!socialNetworks?.length && (
          <div className="cor-footer__social-networks" data-testid="cor-footer__social-networks">
            {socialNetworks?.map(({ icon }, index) => {
              const iconUrl = corContentstackImageLinkResolver(icon)?.url || '';

              return (
                <CorContentstackLink
                  key={index.toString()}
                  contentKey={`${SOCIAL_NETWORKS_KEY}[${index}].icon.link_url`}
                  opensInNewTab
                >
                  <IconWithHover icon={iconUrl} hoverIcon={iconUrl} alt={icon?.link_url?.title} />
                </CorContentstackLink>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(CorFooterLegal);
