import { takeLatest, put, take } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getPaymentFieldTokenActionConstants, getPaymentFieldToken } from 'store/payment-field-token/actions';
import { IAction } from 'types/actions';

export interface IGetPaymentFieldTokenPayload {
  onFailCallBack: (errorCode?: number, errorMsgId?: string) => void;
}
export function* getPaymentFieldTokenHandler() {
  const url = Endpoints.GET_PAYMENT_FIELD_TOKEN;
  yield put(doRequest(getPaymentFieldToken, url, 'post'));
}

export function* getPaymentFieldTokenFailSagaHandler({ payload }: IAction<IGetPaymentFieldTokenPayload>) {
  const { error } = yield take(getPaymentFieldTokenActionConstants[Actions.FAIL]);
  if (payload.onFailCallBack && error) {
    payload.onFailCallBack(error.status, error.errorMessageId);
  }
}

export default function* getPaymentFieldTokenSaga() {
  yield takeLatest(getPaymentFieldTokenActionConstants[Actions.REQUEST], getPaymentFieldTokenHandler);
  yield takeLatest(getPaymentFieldTokenActionConstants[Actions.REQUEST], getPaymentFieldTokenFailSagaHandler);
}
