import { put, take, takeLatest } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { IShoppingList } from 'types/product';
import { importShoppingList, importShoppingListActionConstants } from 'store/shopping-lists/actions';
import { IError } from 'components/modals/import-shopping-list-modal';

export interface IImportShoppingListResult {
  lists: IShoppingList[];
  errorMap: {
    [key: string]: number[];
  };
}

export interface IImportShoppingListPayload {
  csvContents?: string;
  acceptedFile: any;
  onSuccessCallback: (result: IImportShoppingListResult) => void;
  onFailCallback: (error: IError | null) => void;
  body?: any;
}

export function* importShoppingListSuccessSagaHandler({ payload }: IAction<IImportShoppingListPayload>) {
  const {
    payload: { errorMap, shoppingListResponses },
  } = yield take(importShoppingListActionConstants[Actions.SUCCESS]);

  if (payload.onSuccessCallback) {
    payload.onSuccessCallback({
      errorMap,
      lists: shoppingListResponses,
    });
  }
}

export function* importShoppingListFailSagaHandler({ payload }: IAction<IImportShoppingListPayload>) {
  const { error } = yield take(importShoppingListActionConstants[Actions.FAIL]);
  if (payload.onFailCallback && error) {
    payload.onFailCallback(error);
  }
}

export function* importShoppingListHandler({ payload: { acceptedFile } }: IAction<IImportShoppingListPayload>) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  const form = new FormData();
  form.append('file', acceptedFile);
  yield put(doRequest(importShoppingList, Endpoints.IMPORT_SHOPPING_LIST, 'post', form, headers));
}

export default function* importShoppingListSaga() {
  yield takeLatest(importShoppingListActionConstants[Actions.REQUEST], importShoppingListHandler);
  yield takeLatest(importShoppingListActionConstants[Actions.REQUEST], importShoppingListSuccessSagaHandler);
  yield takeLatest(importShoppingListActionConstants[Actions.REQUEST], importShoppingListFailSagaHandler);
}
