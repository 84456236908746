import { FC, useState, useCallback } from 'react';
import classNames from 'classnames';
import filesize from 'filesize';
import { keys, map } from 'lodash-es';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { ContentstackImage, ContentstackText, ContentstackMessage } from 'components/contentstack';
import { importShoppingList } from 'store/shopping-lists/actions';
import { IImportShoppingListPayload, IImportShoppingListResult } from 'store/shopping-lists/sagas/import-shopping-list';
import { LoadingIndicator } from 'components/loading-indicator';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { useContent } from 'hooks/use-content';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';
import { CorButton } from 'components/cor-button';
import { useSuccessErrorMessageModal } from 'hooks/use-global-modal';
import { IAnyObject } from 'types/generic-types';

import './import-shopping-list-modal.scss';

const acceptableContentTypes = [
  'text/csv', // apple
  'application/vnd.ms-excel', // windows
  'text/comma-separated-values', // android
  'text/x-csv',
  'application/csv',
  'application/x-csv',
  // 'text/plain', // reportedly observed, but seems sketchy
];

const errorIdsMap: { [key: string]: string } = {
  invalidFileStructure: 'MSG061',
  incorrectData: 'MSG062',
  emptyMandatoryData: 'MSG063',
  similarShoppingListNaming: 'MSG064',
  incorrectNameLength: 'MSG065',
  unavailableSkus: 'MSG066',
  similarSkus: 'MSG068',
  badFileExtension: 'MSG059',
  emptyFile: 'MSG061',
  emptyImportData: 'MSG061',
};

export interface IError {
  path: string;
  lines: number[];
  type: string;
}

export interface IAcceptedFile {
  path: string;
  lastModified: number;
  lastModifiedDate: IAnyObject;
  name: string;
  type: string;
  size: number;
  webkitRelativePath?: string;
}

interface IImportResults {
  success: boolean;
  apiCallFailed?: boolean;
  hasErrors: boolean;
  itemCount?: number;
  listCount?: number;
  errors?: IError[];
}

export const ImportShoppingListModal: FC<IModalProps> = ({ isOpen = false, onClose = () => {} }) => {
  const contentstackPath = 'modals.0.import_shopping_list_modal.0';
  const successErrorModalContentstackPath = 'modals.0.success_error_modal.0';
  const dispatch = useDispatch();
  const { getContentByKey, getMessageText } = useContent();
  const { isDesktop, isMobile } = useBreakpoint();
  const [didUploadFail, setDidUploadFail] = useState(false);
  const [isExampleExpanded, setExampleExpansionState] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [acceptedFile, setAcceptedFile] = useState<IAcceptedFile | null>(null);
  const [results, setResults] = useState<IImportResults | null>(null);
  const csvTemplateUrl = getContentByKey(`${contentstackPath}.csv_template.url`, '/');
  const browserConfirmationMessage = getContentByKey(`${contentstackPath}.upload_ui.browser_confirmation`, '');
  const [uploadErrorMessage, setUploadErrorMessage] = useState('');

  const showImportSuccessModal = useSuccessErrorMessageModal({
    type: 'success',
  });
  const MAX_UPLOAD_FILE_SIZE = 1000000;

  const resetUploadState = () => {
    setAcceptedFile(null);
    setResults(null);

    setDidUploadFail(false);
    setExampleExpansionState(false);
    setIsSubmitting(false);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      resetUploadState();

      if (acceptedFiles.length === 0) {
        setDidUploadFail(true);
        setUploadErrorMessage(getMessageText('error', 'MSG059'));
      } else if (acceptedFiles[0].size > MAX_UPLOAD_FILE_SIZE) {
        setDidUploadFail(true);
        setUploadErrorMessage(getMessageText('error', 'MSG060'));
      } else {
        setDidUploadFail(false);
        setAcceptedFile(acceptedFiles[0]);
      }
    },
    [getMessageText]
  );
  const { getRootProps, getInputProps, isDragActive, open: openFilePicker } = useDropzone({
    onDrop,
    accept: acceptableContentTypes,
    multiple: false,
    noClick: true,
  });

  const expandExampleSyntax = () => {
    setExampleExpansionState(!isExampleExpanded);
  };

  const onImportSuccess = (results: IImportShoppingListResult) => {
    const compiledResults = compileResults(results);

    setResults(compiledResults);
    setIsSubmitting(false);

    if (!compiledResults?.hasErrors) {
      onClose();

      showImportSuccessModal({
        type: 'success',
        messageId: 'MSG079',
        autoClose: false,
        messageInterpolateParams: {
          itemsQuantity: compiledResults?.itemCount,
          listsQuantity: compiledResults?.listCount,
        },
      });
    }
  };

  const onImportFail = () => {
    // n.b. this runs when the request itself fails; not when we get results back with errors
    setResults({
      hasErrors: false,
      success: false,
      apiCallFailed: true,
    });
    setIsSubmitting(false);
  };

  const submitImport = () => {
    setIsSubmitting(true);
    dispatch(
      importShoppingList.request<IImportShoppingListPayload>({
        acceptedFile: acceptedFile,
        onSuccessCallback: onImportSuccess,
        onFailCallback: onImportFail,
      })
    );
  };

  const compileResults = (response: IImportShoppingListResult): IImportResults => {
    let count: number = 0;

    if (response.lists?.length) {
      count = map(response.lists, 'totalItems').reduce((total, i) => total + i);
    }

    let errors;
    if (response.errorMap) {
      errors = keys(response.errorMap).map((key: string) => {
        return {
          path: errorIdsMap[key],
          type: key,
          lines: response.errorMap[key],
        };
      });

      errors.forEach((errorType) => {
        errorType.lines = errorType.lines.sort((a: number, b: number) => a - b);
      });
    }

    return {
      errors,
      hasErrors: keys(errors).length > 0,
      itemCount: count,
      listCount: response.lists?.length,
      success: count > 0,
    };
  };

  const renderAcceptedFile = (file: IAcceptedFile) => (
    <>
      <hr />
      <div className="upload-icon">
        <ContentstackImage contentKey={`${contentstackPath}.upload_ui.icon`} />
      </div>
      <div className="upload-status">
        <ContentstackText contentKey={`${contentstackPath}.upload_ui.success_message`} />
      </div>
      <div className="upload-details">
        <span className="file-name">{file.name}</span>
        <span className="file-size">{filesize(file?.size)}</span>
      </div>
      <CorButton className="secondary" onClick={openFilePicker}>
        <ContentstackText contentKey={`${contentstackPath}.upload_ui.change_file_button_caption`} />
      </CorButton>
    </>
  );

  const confirmCloseModal = () => {
    onClose();

    // give the transition time to run before resetting state for next use
    const timer = setTimeout(() => {
      resetUploadState();
    }, 500);
    return () => clearTimeout(timer);
  };

  const triggerBrowserWarning = (file: IAcceptedFile) => {
    const browserWarning = window.confirm(browserConfirmationMessage);

    if (browserWarning) confirmCloseModal();
    else {
      setAcceptedFile(file);
      renderAcceptedFile(file);
    }
  };

  const closeModal = () => {
    if (acceptedFile && !results) triggerBrowserWarning(acceptedFile);
    else confirmCloseModal();
  };

  return (
    <>
      <Modal
        size="medium"
        className="import-shopping-list-modal"
        isOpen={isOpen}
        onClose={closeModal}
        withBackdropClick
        hideCloseButton={results !== null && !results.hasErrors}
      >
        {!results && (
          <>
            <ModalHeader
              className="import-shopping-list-modal__header"
              titleContentstackPath={`${contentstackPath}.title`}
            />
            <div
              {...getRootProps({
                className: 'import-shopping-list-modal__drop-zone',
              })}
            >
              <input
                aria-label={getContentByKey<string>(`${contentstackPath}.import_button_caption`, '')}
                data-testid="drop-input"
                {...getInputProps()}
              />
              <div className="import-shopping-list-modal__content">
                <div className="import-shopping-list-modal__instructions">
                  <ol>
                    <li>
                      <a href={csvTemplateUrl} rel="noopener noreferrer" target="_blank">
                        <ContentstackText
                          contentKey={`${contentstackPath}.instructions.step_1.file_download_link_text`}
                        />
                      </a>{' '}
                      <ContentstackText
                        contentKey={`${contentstackPath}.instructions.step_1.file_download_link_appendix`}
                      />
                    </li>
                    <li>
                      <ContentstackText contentKey={`${contentstackPath}.instructions.step_2.instructions`} />{' '}
                      <span
                        className={classNames(['learn-more-handle', isExampleExpanded && 'expanded'])}
                        onClick={expandExampleSyntax}
                      >
                        <ContentstackText contentKey={`${contentstackPath}.instructions.step_2.expansion_link_text`} />
                        <ContentstackImage contentKey={`${contentstackPath}.instructions.step_2.expansion_chevron`} />
                      </span>
                      {isExampleExpanded && (
                        <div className="example">
                          <div className="header">
                            <ContentstackText
                              contentKey={`${contentstackPath}.instructions.step_2.example_format_heading`}
                            />
                          </div>
                          <ContentstackImage
                            contentKey={`${contentstackPath}.instructions.step_2.example_format_image`}
                          />
                        </div>
                      )}
                    </li>
                    <li>
                      {isDesktop && (
                        <ContentstackText contentKey={`${contentstackPath}.instructions.step_3.instructions`} />
                      )}
                      {isMobile && (
                        <ContentstackText contentKey={`${contentstackPath}.instructions.step_3.mobile_instructions`} />
                      )}
                    </li>
                  </ol>
                  <div className="import-shopping-list-modal__upload-ui">
                    {!acceptedFile && !didUploadFail && (
                      <>
                        {isDesktop && (
                          <>
                            <div>
                              <ContentstackText
                                contentKey={`${contentstackPath}.upload_ui.drag_and_drop_instructions`}
                              />
                            </div>
                            <div>
                              <ContentstackText contentKey={`${contentstackPath}.upload_ui.or`} />
                            </div>
                          </>
                        )}
                        <CorButton className="secondary select-file" onClick={openFilePicker}>
                          <ContentstackText contentKey={`${contentstackPath}.upload_ui.select_file_button_caption`} />
                        </CorButton>
                      </>
                    )}
                    {acceptedFile && renderAcceptedFile(acceptedFile)}
                    {didUploadFail && (
                      <>
                        <hr />
                        <div className="upload-icon">
                          <ContentstackImage contentKey={`${contentstackPath}.upload_ui.icon`} />
                        </div>
                        <div className="upload-status unsuccessful">
                          <ContentstackText contentKey={`${contentstackPath}.upload_ui.failure_header`} />
                        </div>
                        <div className="upload-details unsuccessful">{uploadErrorMessage}</div>
                        <CorButton className="secondary select-file" onClick={openFilePicker}>
                          <ContentstackText contentKey={`${contentstackPath}.upload_ui.select_file_button_caption`} />
                        </CorButton>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <ModalFooter className="import-shopping-list-modal__footer">
                <div className="import-shopping-list-modal__footer__action-button">
                  <CorButton onClick={submitImport} disabled={!acceptedFile}>
                    <ContentstackText contentKey={`${contentstackPath}.import_button_caption`} />
                  </CorButton>
                </div>
              </ModalFooter>

              <div className={classNames(['drop-zone-overlay', isDragActive && 'drag-active'])}>
                <div className="instructions">
                  <ContentstackText contentKey={`${contentstackPath}.drop_zone_overlay.instructions`} />
                </div>
                <ContentstackImage contentKey={`${contentstackPath}.drop_zone_overlay.icon`} />
              </div>

              {isSubmitting && (
                <div className="submit-spinner">
                  <LoadingIndicator />
                </div>
              )}
            </div>
          </>
        )}
        {results && (
          <div className="import-shopping-list-modal__results">
            <ModalHeader
              iconType={results.success ? 'success' : 'error'}
              contentstackPathIcon={`${successErrorModalContentstackPath}.${
                results.success ? 'success' : 'error'
              }_icon`}
            >
              {results?.apiCallFailed && (
                <span>
                  <ContentstackMessage type="error" messageId="MSG067" />
                </span>
              )}
              {!results?.success && !results?.apiCallFailed && <ContentstackMessage type="error" messageId="MSG132" />}
              {results?.success && results?.hasErrors && !results?.apiCallFailed && (
                <ContentstackMessage
                  type="success"
                  messageId="MSG079"
                  interpolateParams={{ itemsQuantity: results.itemCount, listsQuantity: results.listCount }}
                />
              )}
            </ModalHeader>
            {results.hasErrors && (
              <>
                <ul className="import-shopping-list-modal__errors">
                  {results.errors?.map((error: IError) => {
                    return (
                      <li key={error.type}>
                        <ContentstackMessage
                          type="error"
                          messageId={error.path}
                          interpolateParams={{ rows: error.lines.join(', ') }}
                        />
                      </li>
                    );
                  })}
                </ul>
                <ModalFooter
                  contentstackPath={successErrorModalContentstackPath}
                  closeButtonHandler={closeModal}
                  hasCloseButton
                />
              </>
            )}
          </div>
        )}
      </Modal>
    </>
  );
};
