import './cor-product-tile-status.scss';

import { FC, useState } from 'react';
import { isEmpty } from 'lodash-es';
import { CorTypography } from 'corporate-ui';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ICorProductTileProps } from 'corporate-components/cor-product-list/components/cor-product-tile';
import {
  useRequestQuoteModal,
  useRequestQuoteSuccessMessageModal,
  useSuccessErrorMessageModal,
} from 'hooks/use-global-modal';
import { ContentstackMessage, ContentstackText } from 'components/contentstack';
import { Routes } from 'constants/routes.enum';
import { stripLeadingZeros } from 'utils/string-format';
import { CustomTooltip } from 'components/custom-tooltip';
import { selectIsKeyAccountUser } from 'store/auth/selectors';
import warningIcon from 'assets/images/icons/icon-warning-red.svg';
import { CorButton } from 'components/cor-button';
import { contactMsr } from 'store/msr/actions';

export interface ICorProductTileStatusProps extends ICorProductTileProps {}

const RenderContactRep = ({
  sku,
  contentStackPath,
  isInactive,
}: {
  sku: string;
  contentStackPath: string;
  isInactive?: boolean;
}) => {
  const dispatch = useDispatch();
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const showContactRepRequestSentModal = useSuccessErrorMessageModal({
    type: 'success',
  });

  const showContactRepRequestFailModal = useSuccessErrorMessageModal({
    type: 'error',
  });

  const onSuccessfulContactRepRequest = () => {
    showContactRepRequestSentModal({ type: 'success', messageId: 'MSG085' });

    setButtonDisabled(false);
  };

  const onFailedContactRepRequest = () => {
    showContactRepRequestFailModal({ type: 'error', messageId: 'MSG189' });

    setButtonDisabled(false);
  };

  const onContactRepLinkClick = (sku: string) => {
    setButtonDisabled(true);

    dispatch(
      contactMsr.request({
        sku,
        onSuccessCallback: onSuccessfulContactRepRequest,
        onFailCallback: onFailedContactRepRequest,
      })
    );
  };

  return (
    <span className="cor-product-tile-status__contact-rep">
      <CorButton
        className="cor-product-tile-status__contact-rep-link"
        onClick={() => onContactRepLinkClick(sku)}
        disabled={isButtonDisabled}
      >
        <ContentstackText contentKey={`${contentStackPath}.contact_rep`} />
      </CorButton>{' '}
      <ContentstackText
        contentKey={isInactive ? `${contentStackPath}.un_available_sku` : `${contentStackPath}.more_information`}
      />
    </span>
  );
};

const RenderSimilarOrAlternativeItems = ({
  sku,
  similarItems,
  contentStackPath,
  hasAlternatives,
  isDiscontinued,
  productKey,
  isUserLocationDeleted,
}: {
  sku?: string;
  similarItems?: string[];
  contentStackPath: string;
  hasAlternatives?: boolean;
  isDiscontinued?: boolean;
  productKey?: string;
  isUserLocationDeleted?: boolean;
}) => {
  const firstSku: string | undefined =
    similarItems && similarItems?.length > 1 ? sku : similarItems?.length ? similarItems[0] : undefined;

  if (!firstSku) {
    return null;
  }

  const alternativeProductKey = firstSku ? stripLeadingZeros(firstSku) : '';

  return (
    <>
      {!!firstSku && (
        <Link
          className={'cor-product-tile-status__button'}
          to={`${Routes.ProductDetailsPage}/${isDiscontinued ? alternativeProductKey : productKey}?selectedSku=${
            isDiscontinued ? firstSku : sku
          }`}
        >
          <ContentstackText
            contentKey={`${contentStackPath}.${isDiscontinued ? 'alternatives' : 'select_similar_items'}`}
          />
        </Link>
      )}
      {sku && (
        <>
          {(hasAlternatives || !isEmpty(similarItems)) && <ContentstackText contentKey={`${contentStackPath}.or`} />}{' '}
          {!isUserLocationDeleted && (
            <RenderContactRep sku={sku} contentStackPath="common[0].shared_recipe_detail_page[0]" />
          )}
        </>
      )}
    </>
  );
};

const RenderRequestQuote = ({
  sku,
  isSpecialOrder,
  contentStackPath,
}: {
  sku: string;
  isSpecialOrder: boolean;
  contentStackPath: string;
}) => {
  const isKeyAccountUser = useSelector(selectIsKeyAccountUser);
  const showRequestQuoteSuccessModal = useRequestQuoteSuccessMessageModal();
  const showRequestQuoteModal = useRequestQuoteModal({
    skuKey: sku,
    requestQuoteSuccessCallBack: showRequestQuoteSuccessModal,
    recipeDetailPageContentstackPath: contentStackPath,
  });

  return (
    <>
      {!isKeyAccountUser ? (
        <div className="cor-product-tile-status__request-quote-button-tooltip">
          <span>
            <button className={'cor-product-tile-status__button'} onClick={() => showRequestQuoteModal()}>
              <ContentstackText contentKey={`${contentStackPath}.request_quote`} />
            </button>
          </span>
          <span>
            <CustomTooltip showTooltip={isSpecialOrder} tooltipId={`recipe-details-${sku}`}>
              <ContentstackMessage type="tooltips" messageId="MSG107" />
            </CustomTooltip>
          </span>
        </div>
      ) : (
        <div className="cor-product-tile-status__special-order-key-account">
          <span className="cor-product-tile-status__special-order-key-account-icon">
            <img src={warningIcon} alt="warning icon" />
          </span>
          <span className="cor-product-tile-status__special-order-key-account-message">
            <ContentstackText contentKey={`${contentStackPath}.contact_customer_service`} />
          </span>
        </div>
      )}
    </>
  );
};

const RenderMessage: FC<ICorProductTileStatusProps> = (props) => {
  const {
    productKey,
    substitutionSku,
    similarItems,
    isOutOfStock,
    isDiscontinued,
    sku,
    isInactive,
    isSpecialOrder,
    isUserLocationDeleted,
  } = props;
  const contentStackPath = 'common[0].shared_recipe_detail_page[0]';
  const noSimilar = !substitutionSku?.length && !similarItems?.length;
  const hasSimilar = !!similarItems?.length;
  const hasAlternatives = !!substitutionSku?.length;

  if (isInactive && !isUserLocationDeleted) {
    return <RenderContactRep sku={sku} contentStackPath={contentStackPath} isInactive={isInactive} />;
  }

  if (isSpecialOrder && !isDiscontinued) {
    return <RenderRequestQuote sku={sku} isSpecialOrder={isSpecialOrder} contentStackPath={contentStackPath} />;
  }

  if (!isUserLocationDeleted && (noSimilar || (!hasAlternatives && isDiscontinued))) {
    return <RenderContactRep sku={sku} contentStackPath={contentStackPath} />;
  }

  if (hasSimilar && isOutOfStock) {
    return (
      <RenderSimilarOrAlternativeItems
        sku={sku}
        similarItems={similarItems}
        contentStackPath={contentStackPath}
        productKey={productKey}
      />
    );
  }

  if (hasAlternatives && isDiscontinued) {
    return (
      <RenderSimilarOrAlternativeItems
        sku={sku}
        similarItems={substitutionSku}
        contentStackPath={contentStackPath}
        isDiscontinued
      />
    );
  }

  return null;
};

const RenderStatus: FC<ICorProductTileProps> = ({
  isDiscontinued,
  isOutOfStock,
  isSpecialOrder,
  isPhasingOut,
  isInactive,
}) => {
  const contentStackPath = 'common[0].shared_recipe_detail_page[0]';
  if (isInactive) return <ContentstackText contentKey={`${contentStackPath}.unavailable`} />;
  if (isDiscontinued && !isPhasingOut) return <ContentstackText contentKey={`${contentStackPath}.discontinued`} />;
  if (isOutOfStock && !isDiscontinued) return <ContentstackText contentKey={`${contentStackPath}.out_of_stock`} />;
  if (isPhasingOut && !isSpecialOrder) return <ContentstackText contentKey={`${contentStackPath}.phasing_out`} />;
  if (isSpecialOrder || (isPhasingOut && isSpecialOrder))
    return <ContentstackText contentKey={`${contentStackPath}.special_order`} />;

  return null;
};

export const CorProductTileStatus: FC<ICorProductTileStatusProps> = (props) => {
  const { isDiscontinued, isOutOfStock, isSpecialOrder, isPhasingOut, price, isInactive } = props;

  return (
    <>
      {isDiscontinued || isOutOfStock || isInactive ? (
        <>
          <CorTypography
            component={'div'}
            className={'cor-product-tile-status__stock'}
            color={'dawn-red'}
            variant={'body-large'}
          >
            <RenderStatus {...props} />
          </CorTypography>
          <CorTypography
            component={'div'}
            className={'cor-product-tile-status__message'}
            variant={'body-regular'}
            color={'mid-gray'}
          >
            <RenderMessage {...props} />
          </CorTypography>
        </>
      ) : (
        <>
          {(isSpecialOrder || (isSpecialOrder && isPhasingOut)) && (
            <>
              <CorTypography
                component={'div'}
                className={'cor-product-tile-status__stock cor-product-tile-status__special-order'}
                color={'green'}
                variant={'body-large'}
              >
                <RenderStatus {...props} />
              </CorTypography>
              <CorTypography
                component={'div'}
                className={'cor-product-tile-status__message'}
                variant={'body-regular'}
                color={'mid-gray'}
              >
                <RenderMessage {...props} />
              </CorTypography>
            </>
          )}
          {isPhasingOut && !isSpecialOrder && (
            <CorTypography
              component={'div'}
              className={'cor-product-tile-status__stock'}
              color={'dawn-red'}
              variant={'body-large'}
            >
              <RenderStatus {...props} />
            </CorTypography>
          )}
          {!!price?.amount && (
            <CorTypography
              component={'div'}
              className={'cor-product-tile-status__price'}
              color={'dark-gray'}
              variant={'body-regular'}
            >
              {`${price?.currencySymbol}${price?.amount}`}
            </CorTypography>
          )}
        </>
      )}
    </>
  );
};
